import axios from 'axios';
import Chat from '../vendors/chat';
import Popup from '../utils/popup';
import VimeoPlayer from '../utils/vimeo';
import gsap from 'gsap'
import Atelier from '../modules/atelier'
import Live from '../modules/live'
import Sidebar from '../utils/sidebar';
import Countdown from '../utils/countdown';

const INTERVAL_REQUEST = 30000;
const HTML = document.querySelector('html');
const Modules = {
  atelier : Atelier,
  live : Live
}

export default class Module {
  constructor(opts) {    
    this.current_module = false; 
    this.opts = opts;
    this.opts.id_event = 'timeline_' + this.opts.event.slug ;


    // Active panel
    this.activePanel = document.querySelector('.panel.active');

    this.app = document.querySelector('.app')
    
    //------------------
    //--- Build chat
    //------------------
    this.buildChat()

    HTML.classList.add('show-block-user')

    // Tuto step
    if( event['auth'] != 'free') {
      this.intro();
    } else {
      this.getState();
    }
  }

  init() {
    
    //------------------
    //--- Hide chat 
    //------------------
    this.hideChat()
    
    //------------------
    //--- Check the state event
    //------------------
    this.getState();
    
    //------------------
    //--- Init bypass
    //------------------
    this.bypass();
  }

  /**
   * Init event on tuto panel (intro panel)
   */
  intro() {
    let _ = this;

    // Trigger continue
    let triggerContinue = document.querySelector('.trigger-continue');
    if( triggerContinue ) {
      triggerContinue.addEventListener('click', (e) => {
        e.preventDefault();
        HTML.classList.add('show-chat')
        _.init();
      })
    }

    // Create popup tuto
    let triggerTuto = document.querySelector('.trigger-tuto');
    if( triggerTuto ) {
      triggerTuto.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Create video tuto
        let tuto = document.createElement('div');
        tuto.classList.add('tuto', 'u-tac');
        let tutoContent = document.createElement('div');
        tutoContent.classList.add('tuto__content')
        tuto.append(tutoContent);

        let player = new VimeoPlayer(tutoContent, tuto, {
          id : triggerTuto.dataset.video,
          fullscreen: false,
          autoplay: true,
          width : '100%',
          controls : true,
          features: {
            fullscreen : false,
            volume : false
          },
        });

        tuto.querySelector('video').classList.add('video')

        // Create popup
        let popup = new Popup(tuto)

        // Create button skip
        if( triggerContinue ) {
          let skip = document.createElement('a')
          skip.classList.add('btn');
          skip.innerHTML = 'Passer le tuto';
          tuto.append(skip);

          function closeOverlay(e) {
            if( !e.target.classList.contains('video') && !e.target.closest('.video') &&
                !e.target.classList.contains('btn') && !e.target.closest('.btn') ) {
              e.stopPropagation();
              e.preventDefault();
              
              if( close ) {
                skip.dispatchEvent(new Event('click'));
              }
              
            }
          }
    
          HTML.addEventListener('click', closeOverlay);

          skip.addEventListener('click', e => {
            e.preventDefault();

            triggerContinue.dispatchEvent(new Event('click'));
            player.pause();
            popup.close()
            HTML.removeEventListener('click', closeOverlay);
            _.init();
          })

          player.on('ended', () => {
            skip.dispatchEvent(new Event('click'));
            _.init();
          })
        }
      })
    }
  }


  /**
   * Create hotline and main chat and private chat
   */
  buildChat() {

    this.opts.id_event = 'timeline_' + this.opts.event.slug ;
    
    // Create chat
    this.chat = new Chat(this.opts);
    let chatElt = document.querySelector('.chat')


    if( chatElt ) {
  
      // Join the general chat
      this.chat.joinChat(this.opts.user, this.opts.event.slug).then(() => {
        // On récupère toutes les conversations hors conv général
        this.chat.getAllConversations();
  
        // Listener sur les nouveaux conv
        this.chat.unreadConversation();
  
        // Create button for add chat
        this.chat.buttonConversation();

        const operator = document.querySelector('[data-operator]');
        if( operator ) {
          this.chat.operator(operator).then(() => {
            this.buildHotline();
          });
        } else {
          // Create hotline
          this.buildHotline();
        }
      })
    } else {
      // Chat operator
      const operator = document.querySelector('[data-operator]');
      if( operator ) {
        this.chat.operator(operator).then(() => {
          this.buildHotline();
        });
      } else {
        this.buildHotline();
      }
    }
  }

  buildHotline() {
    let _ = this;
  
    // Create chat hotline
    let hotline = document.querySelector('.hotline');
    if( hotline ) {
      this.chat.hotline(hotline.querySelector('.hotline__chat'));

      // Trigger chat
      hotline.querySelector('.hotline--trigger').addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        HTML.removeEventListener('click', toggleHotline)

        HTML.classList.toggle('show-hotline')
        HTML.addEventListener('click', toggleHotline)
      })

      function toggleHotline(e) {
        if( HTML.classList.contains('show-hotline') ) {
          if( !e.target.classList.contains('hotline') && !e.target.closest('.hotline') ) {
            e.preventDefault();
            HTML.classList.remove('show-hotline')
            HTML.removeEventListener('click', toggleHotline)
          }
        }
      }
    }
  }

  /**
   * Hide the chat panel - init the trigger chat panel
   */
  hideChat() {
    let chatMain = document.querySelector('.chat__main');
    if( chatMain ) {
      setTimeout(()=> {
        chatMain.classList.add('hide-chat')
      }, 1500)

      // Trigger chat
      let trigger = chatMain.querySelector('.chat--trigger');
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation()

        chatMain.classList.toggle('hide-chat')
      })
    }
  }

  getState() {
    const _ = this;

    return axios({
      method: 'get',
      url: `${url}inc/.ajax.php?action=state&id=${event.id}`
    })
    .then(function (response) {
      if( response.data && response.data.output ) {
        _.loadModule(response.data.output);
      }
      if( !bypass ) {
        setTimeout(() => {
          _.getState()
        }, INTERVAL_REQUEST)
      }
    })
    .catch(() => {
      if( !bypass ) {
        setTimeout(() => {
          _.getState()
        }, INTERVAL_REQUEST)
      }
    })
  }

  loadModule(module) {
    const _ = this;
    if( !_.current_module || _.current_module.id != module.id ) {
      window.dispatchEvent(new Event('destroy.workshop'));

      HTML.classList.remove(`module--${_.current_module.id}`)
      HTML.classList.add(`module--${module.id}`)

      _.current_module = module;

      // Hide old active panel
      const oldPanel = _.activePanel
      gsap.to(_.activePanel, { duration : 0.3, autoAlpha : 0, onComplete : () => {
        oldPanel.remove();
      }});

      // Show next active panel
      const m = new Modules[module.type](Object.assign(_.opts, { module : module, chat: _.chat }))
      m.load().then((panel) => {
        _.activePanel = panel;

        panel.classList.add('active');
        gsap.set(panel, {autoAlpha : 0})
        _.app.append(panel);
        
        new Sidebar();
        gsap.to(panel, { duration : 0.3, autoAlpha : 1, delay : 0.2});
        
        // Create module question
        let question = panel.querySelector('.question-module');
        if( question ) {
          _.buildQuestionModule(question);
        }

        // Create countdown if exist
        let countdowns = panel.querySelectorAll('.countdown');
        if( countdowns ) {
          countdowns.forEach(c => {
            new Countdown(c);
          })
        }

        m.init();
      })
    }
  }


  bypass() {
    const _ = this
    const el = document.querySelector('.bypass');

    if( el ) {
      const select = el.querySelector('select')
      const trigger = el.querySelector('.trigger')

      trigger.addEventListener('click', e => {
        e.preventDefault();

        return axios({
          method: 'get',
          url: `${url}inc/.ajax.php?action=get-module&id=${select.value}`
        })
        .then(function (response) {
          if( response.data ) {
            _.loadModule(response.data);
          }
        })
      })
    }
  }

  buildQuestionModule(el) {
    const form = el.querySelector('form')
    if( form ) {
      const trigger = el.querySelector('.question-module__trigger')
      if( trigger ) {
        trigger.addEventListener('click', (e) => {
          e.preventDefault();

          el.classList.toggle('show')
        })
      }
      const close = el.querySelector('.trigger-close');
      if( close ) {
        close.addEventListener('click', (e) => {
          e.preventDefault();

          el.classList.remove('show')
        })
      }

      let textarea = form.querySelector('textarea');
      if( textarea ) {
        let notice = textarea.nextElementSibling;
        if( notice && notice.classList.contains('notice')) {
          let chars = notice.querySelector('.nb-chars');
          let limit = parseInt(chars.dataset.limit)
          textarea.addEventListener('keydown', e => {
            // if(e.keyCode == 8 || e.keyCode == 13 )
            if( textarea.value.length >= limit && e.keyCode != 8 ) {
              e.preventDefault();
              textarea.value = textarea.value.substring(0, limit);
            }

            chars.innerHTML = limit - textarea.value.length
          })
        }
      }


      form.addEventListener('submit', (e) => {
        e.preventDefault();

        let msgs = form.querySelectorAll('.msg')
        if( msgs ) {
          msgs.forEach(m => {
            m.remove();
          })
        }

        form.classList.add('onloading');
        const values = new FormData(e.target);

        let msg = document.createElement('div');
        msg.classList.add('msg')

        if(values.get('nom') == '' || values.get('question') == '' ) {
          msg.classList.add('error');
          msg.innerHTML = "Les champs marqués (*) sont obligatoire."
          return false;
        }

        axios({
          method: 'post',
          data : values,
          url: `${url}inc/.ajax.php?action=post-question`
        })
        .then(function (response) {
          form.prepend(msg);
          form.classList.remove('onloading');

          if( response.data) {
            if( response.data.statut ) {
              msg.classList.add('success')
              msg.innerHTML = 'Votre question a bien été envoyée.' 

              form.reset();
              if( textarea ) {
                let notice = textarea.nextElementSibling;
                if( notice && notice.classList.contains('notice')) {
                  let chars = notice.querySelector('.nb-chars');
                  let limit = parseInt(chars.dataset.limit)
                  chars.innerHTML = limit;
                }
              }
            } else {
              msg.classList.add('error')
              msg.innerHTML = response.data.msg;
            }
          } else {
            msg.classList.add('error')
            msg.innerHTML = "Une erreure s'est produite, merci de réessayer ultérieurement."
          }

          setTimeout(() => {
            msg.remove();
          }, 5000);
        })
        .catch((response) => {
          form.classList.remove('onloading');
        })

        return false;
      })
    }
  }
}