import Vimeo from '@vimeo/player';
import gsap from 'gsap'
import Hls from 'hls.js'

export default class VimeoPlayer {
  constructor(playerElt, container, opts = {} ) {
    let _ = this;

    _.opts = {
      features: {
        fullscreen : true,
        volume : true
      },
      controls: false,
      autoplay: true,
      title: false,
      loop: false,
      byline: false,
      tooltip: false,
      fullscreen: true,
      adaptive: false,
    }

    this.changeVolState = true;
    this.currentVol = 1;

    this.controlsElt = document.createElement('div');
    this.controlsElt.classList.add('video__controls');
    container.append(this.controlsElt);

    Object.assign(_.opts,opts);
    
    if( _.opts.id.indexOf('.m3u8') >= 0 ||
        _.opts.id.indexOf('.mp4') >= 0 ) {
      _.player = document.createElement('video');
      playerElt.append(_.player);

      if(  _.opts.features ) {
        // _.player.setAttribute('controls', _.opts.controls);
      }


      if (Hls.isSupported() && _.opts.id.indexOf('.m3u8') >= 0 ) {
        var hls = new Hls();
        hls.loadSource(_.opts.id);
        hls.attachMedia(_.player);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          _.player.play();
        });
      }
      else if ( _.player.canPlayType('application/vnd.apple.mpegurl') ||
                _.opts.id.indexOf('.mp4') >= 0 ) {
        _.player.src = _.opts.id;
        _.player.addEventListener('loadedmetadata', function() {
          if( _.opts.autoplay ) _.player.play();
        });
      }


      if(_.opts.tooltip ) {
        _.player.addEventListener('pause', () => {
          let btn = document.createElement('a');
          btn.classList.add('btn', 'trigger-play')
          btn.innerHTML = 'Prêt pour commencer l\'événement !';
          container.appendChild(btn);
  
          btn.addEventListener('click', (e) => {
            e.preventDefault();
            _.player.play();
            btn.remove();
          })
        })
      }

      if(_.opts.fullscreen ) _.player.classList.add('fullscreen')
        
      const wVideo = 1920
      const hVideo = 1080
      let ratioVideo = wVideo/hVideo;

      window.addEventListener('resize.fullscreen', (e) => {     
        let wW = container.offsetWidth;
        let hW = container.offsetHeight;

        
        let wNew = ratioVideo > 1 ? wW : wVideo * hW / hVideo;
        let hNew = ratioVideo > 1 ? hVideo * wW / wVideo : hW;
        
        if( _.opts.fullscreen ) {
          wW = window.innerWidth;
          hW = window.innerHeight;
          
          wNew = ratioVideo > wW/hW ? wVideo * hW / hVideo : wW;
          hNew = ratioVideo > wW/hW ? hW : hVideo * wW / wVideo;
          _.player.classList.add('fullscreen')
        } else if( _.opts.adaptive) {
          _.player.classList.remove('fullscreen')
        } else {
          wW = window.innerWidth;
          wNew = wW * 0.7;
          hNew = wNew * hVideo / wVideo
          _.player.classList.remove('fullscreen')
        }


        _.player.style.width = `${wNew}px`;
        _.player.style.height = `${hNew}px`;
        window.dispatchEvent(new Event('resize.chat'));
      })
      
    } else {
      _.player = new Vimeo(playerElt, _.opts);

      Promise.all([_.player.getVideoWidth(), _.player.getVideoHeight()]).then(function(dimensions) {
        let wVideo = dimensions[0];
        let hVideo = dimensions[1];
        let ratioVideo = wVideo/hVideo;
        let iframe = playerElt.querySelector('iframe');

        window.addEventListener('resize.fullscreen', (e) => {
          let wW = container.offsetWidth;
          let hW = container.offsetHeight;

          
          let wNew = ratioVideo > 1 ? wW : wVideo * hW / hVideo;
          let hNew = ratioVideo > 1 ? hVideo * wW / wVideo : hW;
          
          if( _.opts.fullscreen ) {
            wW = window.innerWidth;
            hW = window.innerHeight;
            
            wNew = ratioVideo > wW/hW ? wVideo * hW / hVideo : wW;
            hNew = ratioVideo > wW/hW ? hW : hVideo * wW / wVideo;
          } else if( _.opts.adaptive) {
          } else {
            wW = window.innerWidth;
            wNew = wW * 0.7;
            hNew = wNew * hVideo / wVideo
          }


          iframe.style.width = `${wNew}px`;
          iframe.style.height = `${hNew}px`;
          window.dispatchEvent(new Event('resize.chat'));
        })
        
        window.dispatchEvent(new Event('resize.fullscreen'))

        _.player.on('play', (e) => {
          iframe.classList.add('show');
        })

        if(_.opts.tooltip ) {
          _.player.on('pause', () => {
            let btn = document.createElement('a');
            btn.classList.add('btn', 'trigger-play')
            btn.innerHTML = 'Prêt pour commencer l\'événement !';
            container.appendChild(btn);

            btn.addEventListener('click', (e) => {
              e.preventDefault();
              _.player.play();
              btn.remove();
            })
          })
        }
      });
    }

    if( _.opts.features.volume ) {
      this.createVolume(this.controlsElt, _.player);
    }

    // Create button fullscreen
    if( _.opts.features.fullscreen) {
      this.createFullscreen();
    }

    // Create progress bar
    this.createProgress(container);

    return this;
  }

  createProgress(container) {
    const _ = this;
    const progress = container.querySelector('.player__progress')
    if( progress ) {
      const duration = parseFloat(progress.dataset.duration)
      const bar = progress.querySelector('.bar');

      // this.on('timeupdate', e => {
      //   if (_.player instanceof HTMLElement) {
      //   } else {
      //     _.player.getCurrentTime().then(function(seconds) {
      //       bar.style.width = `${seconds / (duration * 60)}%`;
      //     });
      //   }
      // })

      // Create keypoints
      const keypoints = progress.querySelectorAll('.keypoint__item');
      let current = -1;
      if( keypoints ) {
        keypoints.forEach((k, i) => {
          let triggers = k.querySelectorAll('.keypoint__item--thumb, .trigger-close');
          triggers.forEach((t) => {
            t.addEventListener('click', e => {
              e.preventDefault();

              if( current >= 0 && current != i ) keypoints[current].classList.remove('show');
              k.classList.toggle('show');
              current = i;
            })
          })
        })
      }
    }
  }

  createFullscreen() {
    let _ = this;
    const btn = document.createElement('a');
    btn.classList.add('btn-rounded', '-border', 'trigger-video-fullscreen');
    btn.innerHTML = '<i class="icon--fullscreen"></i>'
    _.controlsElt.append(btn);

    btn.addEventListener('click', e => {
      e.preventDefault();
      _.opts.fullscreen = !_.opts.fullscreen;
      btn.classList.toggle('unminimize')
      window.dispatchEvent(new Event('resize.fullscreen'));
    })
  }

  createVolume(container, player) {
    const _ = this;
    let volumeElt = document.createElement('div');
    volumeElt.classList.add('volume');
    container.append(volumeElt);

    let bar = document.createElement('div');
    bar.classList.add('volume--bar')
    bar.innerHTML = '<span></span>';
    volumeElt.append(bar);

    let a = document.createElement('a')
    a.href = '#';
    a.classList.add('btn-rounded', '-border')
    a.innerHTML = '<i class="icon icon--volume"></i>';
    volumeElt.append(a)

    // player.getVolume().then(function(volume) {
    // });

    bar.addEventListener('click', (e) => {
      e.preventDefault();
      this.changeVolState = true;

      let pos = bar.getBoundingClientRect();
      let height = bar.offsetHeight;
      
      let vol = (pos.bottom - e.clientY) / height;

      if (player instanceof HTMLElement) {
        player.volume = vol;
      }
      else {
        player.setVolume(vol);
      }
    })

    // Volume link muted
    a.addEventListener('click', (e) => {
      e.preventDefault();
      this.changeVolState = true;

      if (player instanceof HTMLElement) {
        player.volume = !volumeElt.classList.contains('muted') ? 0 : 1;
      } else {
        player.setVolume(!volumeElt.classList.contains('muted') ? 0 : 1);
      }
    })

    function volumeChange(el) {
      _.currentVol =  el.volume;

      if( _.changeVolState ) {
        if( el.volume == 0) {
          volumeElt.classList.add('muted');
        } else {
          volumeElt.classList.remove('muted');
        }
  
        gsap.set(bar.querySelector('span'), { scaleY : el.volume });
      }
    }

    if (player instanceof HTMLElement) {
      player.addEventListener('volumechange', () => {
        volumeChange(player);
      })
    } else {
      player.on('volumechange', (volume) => {
        volumeChange(volume);
      })
    }
  }

  play() {
    let _ = this;
    _.player.play();
  }

  pause() {
    let _ = this;
    _.player.pause();
  }

  mute(changeState = true) {
    this.changeVolState = changeState;
    this.player.setVolume(0)
  }

  unmute(changeState = true) {
    this.changeVolState = changeState;
    this.player.setVolume(1)
  }

  on(event, callback) {
    let _ = this;

    if (_.player instanceof HTMLElement) {
      _.player.addEventListener(event, callback);
    } else {
      _.player.on(event, callback);
    }
  }
}