import { ZoomMtg } from '@zoomus/websdk';
import axios from 'axios';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.0/lib', '/av');
ZoomMtg.setZoomJSLib(`${url}src/js/vendors/zoom/lib`, '/av');

// Add zoom meeting Dom element in element from the app
const zoomMeeting = document.getElementById("zmmtg-root")


export default class Zoom {
  constructor(elt, opts, chat = null) {
    this.opts = opts;
    this.elt = elt
    this.chat = chat;

    ZoomMtg.i18n.load("fr-FR");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    elt.querySelector('.container').appendChild(zoomMeeting);

    this.join(this.opts.zoomId, this.opts.zoomPass, this.opts.user.email, 0);

    // Create chat 
    chat.joinChat(this.opts.user, `${this.opts.event}-zoom-${this.opts.zoomId}`, '.zoom__chat', 'Chat zoom');

  }

  join(meetingNumber, password, email, role) {
    let _ = this;
    
    // GENERATE SIGNATE
    axios({
      method: 'get',
      url: `${url}/inc/.ajax.php?action=generate-signature&meeting_number=${meetingNumber}&role=${role}`
    })
    .then(function (response) {
      if( response.data) {
        ZoomMtg.init({
          leaveUrl: url_event.substring(0, url_event.length-1),
          isSupportAV: true,
          screenShare: false,
          showMeetingHeader: false,
          isSupportChat: false,
          isSupportQA: false,
          isSupportCC: false,
          disableInvite: true, 
          disableCallOut: true, 
          disableRecord: true, 
          // disableJoinAudio: true, 
          audioPanelAlwaysOpen: false,
          success: () => {

            console.log(_.opts.user);
            
            ZoomMtg.join({
              signature: response.data,
              meetingNumber: meetingNumber,
              userName: _.opts.user.email,
              // userEmail: _.opts.user.email,
              apiKey: zoomApiKey,
              passWord: password,
              success: (success) => {
                _.getAttendeeslist();
              },
              error: (error) => {
                console.log(error)
              }
            })
          },
          error: (error) => {
            console.log(error)
          }
        })
      }
    }) 
  }

  /**
   * Get users connected
   */
  getAttendeeslist() {
    let _ = this;


    ZoomMtg.getAttendeeslist({
      success: (data) => {
        if( data.result && data.result.attendeesList) {
          let attendees = [];
          data.result.attendeesList.forEach(attendee => {
            if( !attendee.isHost) {
              attendees.push(attendee.userName)
            }
          })
          _.chat.getUsers(attendees.join(',')).then(response => {
            _.buildAttendeesList(response.data)

            // Launch the function every 10 secondes
            setTimeout(() => {
              _.getAttendeeslist();
            }, 10000);
          });
        }
      }
    })
  }

  /**
   * Build the HTML list from users connected
   * 
   * @param {Array} users : list of user 
   */
  buildAttendeesList(users) {
    let _ = this;

    let list = _.elt.querySelector('.zoom__users');
    let ul = list.querySelector('ul');
    ul.innerHTML = '';

    let usersConnected = []

    if( users.length > 0) {
      users.forEach(user => {

        if( !usersConnected.includes(user.email)) {
          let li = document.createElement('li');
          let a = document.createElement('a')
          li.append(a);
          ul.append(li);
  
          a.innerHTML = `<img src="${user.avatar}" /><span>${user.nickname ? user.nickname : user.email}</span>`;
          a.addEventListener('click', e => {
            e.preventDefault();
 
            _.chat.createDiscussion([user]);
          })

          usersConnected.push(user.email);
        }
      })

      // Update num
      list.querySelector('.zoom__users--num span').innerHTML = usersConnected.length;
    }
  }


  destroy() {
    ZoomMtg.leaveMeeting();
  }
}