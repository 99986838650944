import Talk from 'talkjs';
import axios from 'axios';
import Cookies from 'js-cookie'

const HTML = document.querySelector('html');
const maxDisplayConvs = 3;

export default class Chat {
  constructor(opts) {
    let _ = this;

    this.opts = opts;
    this.me = null;

    this.convs = [];

    // Get cookie from user
    // Cookies.remove('deleteConvs');
    if( !Cookies.get('deleteConvs')) {
      Cookies.set("deleteConvs", []);
    }
  }

  /**
   * Create talk js discussion popup
   * 
   * @param {[]} users : array of all users
   */
  createDiscussion(users) {
    let _ = this;

    // Build id conversation
    let emails = [_.me.email];
    users.forEach((u) => {
      let e = typeof u.email == 'string' ? u.email : u.email[0];
      emails.push(e);
    })
    emails.sort();
    let idConv = this.opts.id_event + '-' + emails.join('|');

    var conversation = talkSession.getOrCreateConversation(idConv);

    // On cache les autres chat
    let otherChat = document.querySelectorAll('.chat__private');
    if( otherChat ) {
      otherChat.forEach(el => {
        el.classList.remove('show')
      })
    }

    if(_.isNewConversation(conversation) == true ) {

      conversation.setParticipant(_.me);

      users.forEach((u) => {
        let email = typeof u.email == 'string' ? u.email : u.email[0];
        let name = u.name ? u.name : u.nickname;

        let _user = new Talk.User({
            id: this.opts.id_event + '_' + email,
            name: name,
            email: email,
            role: "timeline"
        });
        conversation.setParticipant(_user);
      })
      
      _.createChatbox(conversation);
    } else {
      // active conversation 
      let target = document.querySelector('#conv-' + conversation.internalId)
      if(target) {
        target.classList.add('show');
      } else {
        let link = document.querySelector('#conv-hidden-' + conversation.internalId)

        if( link ) link.dispatchEvent(new Event('click'));
      }
    }
  }

  
  /**
   * Create button + box chat
   * 
   * @param {Conversation TalkJS} conversation : conversation talkJS
   */
  createChatbox(conversation, show = true, classes = '', dir = 'after', parent = null) {
    let _ = this;
    var popup = talkSession.createChatbox(conversation);

    _.convs.push(conversation);

    _.updateDeletedConversations(conversation);

    // Reinit all state
    let showChat = document.querySelectorAll('.chat__private.show')
    if( showChat ) {
      showChat.forEach(c => c.classList.remove('show'))
    }

    // Container
    let privateChat = document.createElement('div');
    privateChat.classList.add('chat__private');
    if( classes != '' ) privateChat.classList.add(classes);
    privateChat.setAttribute('id', 'conv-' + conversation.internalId)
    if( show ) privateChat.classList.add('show');

    // Chat remove conv button
    let chatDel = document.createElement('i');
    chatDel.classList.add('chat__private-del')
    chatDel.addEventListener('click', e => {
      e.preventDefault();

      let deleteConvs = eval(Cookies.get("deleteConvs"));
      deleteConvs.push(conversation.id);
      Cookies.set('deleteConvs', deleteConvs);

      _.convs.forEach((c, i)  => {
        if( c.id == conversation.id ) {
          _.convs.splice(i, 1);
          return false;
        }
      })

      // popup.destroy();
      // privateChat.remove();
      privateChat.style.display = 'none';
      // _.limitDisplayConversations()
    })

    // Thumbs button conve
    let chatBtn = document.createElement('a');
    chatBtn.classList.add('chat__private--btn')
    chatBtn.addEventListener('click', e => {
      e.preventDefault();

      // On cache les autres chat
      let otherChat = document.querySelectorAll('.chat__private');
      if( otherChat ) {
        otherChat.forEach(el => {
          if( el != privateChat ) el.classList.remove('show')
        })
      }

      privateChat.classList.toggle('show')

      if( privateChat.classList.contains('show') ) {
        privateChat.classList.remove('new', 'unread');
      }
    })

    // On construit le nom de la conv
    let name = '';
    let counterConv = _.convs.length - 1;
    let participants = _.getParticipants(conversation);
    if( participants ) {
      Promise.all([_.getUsers(participants.join(','))])
        .then(function (results) {
          if( results.length > 0 ) {
            let users = results[0].data
            
            users.forEach((p, i) => {
              if( p.email != _.me.email ) { 

                if( i <= 2 ) {
                  let img = '<img src="' + p.avatar + '" />';
          
                  name += `<span>${img}</span>`;
                }
              }
            })
            chatBtn.innerHTML = name;
            chatBtn.innerHTML += '<i class="pulse"></i>';
            chatBtn.appendChild(chatDel)

            conversation.participants = users;
            _.convs.splice(counterConv, 1, conversation)

            if( users.length > 2 ) {
              chatBtn.classList.add('multiple')
            }
          }

          _.limitDisplayConversations()
        });
    }

    let chatContainer = document.createElement('div');
    chatContainer.classList.add('chat__private--container')

    let btnMinus = document.createElement('a');
    btnMinus.classList.add('chat__private--minus');
    btnMinus.addEventListener('click', (e) => {
      e.preventDefault();
      chatBtn.dispatchEvent(new Event('click'));
    })
    chatContainer.append(btnMinus)

    // Container for the talkjs conv
    let chatContent = document.createElement('div');
    chatContent.classList.add('chat__private--content')
    chatContainer.append(chatContent)

    privateChat.appendChild(chatBtn);
    privateChat.appendChild(chatContainer);
    if( parent != null ) {
      parent.prepend(privateChat);
    } else {
      document.querySelector('.chat__private-convs').prepend(privateChat);
    }

    popup.on("focus", () => {
      privateChat.classList.remove('new', 'unread');
    })

    popup.mount(chatContent);
  }

	/**
	 * 
	 * @param {string} email : email for the user
	 */
	joinChat(user, event, container = '.chat', title = 'Forum' ) {
    let _ = this;

  	return new Promise((resolve, reject) => {
      Talk.ready
        .then(() => {

          _.me = new Talk.User({
              id: this.opts.id_event + '_' + user.email,
              name: (user.nickname ? user.nickname : user.email),
              email: user.email,
              photoUrl : user.avatar,
              role: "timeline"
          }); 
      
          if (!window.talkSession) {
              window.talkSession = new Talk.Session({
                  appId: appId,
                  me: _.me
              });
          }
      
          const conversation = window.talkSession.getOrCreateConversation("conv-general-"+event);
          conversation.setParticipant(_.me);

          conversation.setAttributes({
            subject: title,
            photoUrl : `${url}/dist/svg/chat-all.svg`
          })
          this.inbox = talkSession.createChatbox(conversation, {
            chatTitleMode : 'subject',
            chatSubtitleMode : null,
            showFeedHeader: false,
            // translateConversations: true,
            // showTranslationToggle: true,
          }) 
      
          // this.inbox = window.talkSession.createInbox({
          // 		selected: conversation,
          // 		chatTitleMode : 'subject',
          // 		chatSubtitleMode : null,
          // 		showFeedHeader: false
          // });
          this.inbox.mount(document.querySelector(container));

          resolve(this);
        }, function (e) { console.log(e) }).catch(function (e) { console.log(e) });
    })
  }

  operator(elt) {
    const _ = this;

    return new Promise((resolve, reject) => {
      Talk.ready
        .then(() => {
          const emailOperator = elt.dataset.operator;

          this.getUsers([emailOperator]).then(response => {
            if( response.data ) {
              const o = response.data[0];

              const operator = new Talk.User({
                id: this.opts.id_event + '_' + emailOperator,
                name: o.firstname + ' ' + o.name,
                email: emailOperator,
                photoUrl : o.avatar,
                role: "operator",
                welcomeMessage: this.opts.event.slug == 'icncampus' || this.opts.event.slug == 'id2apac-socomec' || this.opts.event.slug == 'id2emea-socomec' ? "Hello, how can I help you ?" : "Bonjour, en quoi puis-je vous aider ?"
              }); 
              
              if( !_.me) {
                _.me = new Talk.User({
                    id: this.opts.id_event + '_' + user.email,
                    name: (user.nickname ? user.nickname : user.email),
                    email: user.email,
                    photoUrl : user.avatar,
                    role: "timeline"
                }); 
              }

              if (!window.talkSession) {
                  window.talkSession = new Talk.Session({
                      appId: appId,
                      me: _.me
                  });
              }
              const idConv = this.opts.id_event + '-' + user.email + '|' + emailOperator;
              const conversation = window.talkSession.getOrCreateConversation(idConv);
              conversation.setParticipant(_.me);
              conversation.setParticipant(operator); 

              this.createChatbox(conversation, true, '', 'after', elt);
              resolve(this);

              this.allConversations().then((response) => {
                let exist = false;

                if( response.data && response.data.data ) { 
                  response.data.data.forEach(c => {
                    if(c.id == idConv) exist = true;
                  })
                }

                if( !exist && this.opts.event.slug != 'insa' ) {
                  if( this.opts.event.slug != 'id2apac-socomec' || this.opts.event.slug != 'id2emea-socomec' ) {
                    conversation.sendMessage('Hello !');
                  } else {
                    conversation.sendMessage('Bonjour !'); 
                  }
                }
              })
            }
          })
        }, function (e) { console.log(e) }).catch(function (e) { console.log(e) });
    })
  }

  /**
   * Create chat hotline
   */
  hotline(elt) {
    const _ = this;
    
    if( _.me == null ) {
      
      Talk.ready
        .then(() => {
          axios({
            url : 'https://www.cloudflare.com/cdn-cgi/trace'
          })
          .then(response => {
            let ip = 'ukw';

            if(response.data) {
              let regIp = response.data.match(/ip=([^\n])*/g);

              if( ip.length ) {
                ip = regIp[0];
              }
            }
            let email = `johndoe-${ip}@timeline-event.com`
  
            _.me = new Talk.User({
              id: _.opts.id_event + '_' + email,
              name: `John Doe - ${ip}`,
              email: email,
              role: "timeline"
            }); 
        
            if (!window.talkSession) {
                window.talkSession = new Talk.Session({
                    appId: appId,
                    me: _.me
                });
            }
  
            create();

          })
        })
    } else {
      create();
    }

    function create() {
      let operator = new Talk.User({
        id: "operator-timeline",
        name: "Hotline",
        email: "hello@timeline-event.com",
        role: "hotline",
        photoUrl: `${url}/src/svg/hotline-avatar.svg`,
        welcomeMessage: translation && translation['en'] ? "Hello, how can I help you ?" : "Bonjour, comment puis-je vous aider ?"
      });
  
      var conversation = window.talkSession.getOrCreateConversation(_.opts.id_event + '-hotline_' + _.me.email );
      conversation.setParticipant(_.me);
      conversation.setParticipant(operator);
  
      var chatbox = window.talkSession.createChatbox(conversation);
      chatbox.mount(elt);
    }
  }

  getAllConversations() {
    let _ = this;

    this.allConversations()
      .then(function (response) {
        if( response.data && response.data.data ) {
          // On récupère les conversations que l'utilisateur a supprimé 
          let deleteConvs = eval(Cookies.get("deleteConvs"));
          response.data.data.reverse()

          response.data.data.forEach((conv, i) => {
            if(_.isNewConversation(conv) && !deleteConvs.includes(conv.id)) {
              let conversation = talkSession.getOrCreateConversation(conv.id);

              _.createChatbox(conversation, false);
            }
          })
        }
      })
  }

  allConversations() {
    let _ = this;

    return axios({
      method: 'get',
      url: `https://api.talkjs.com/v1/${appId}/users/${this.opts.id_event + '_' + this.opts.user.email}/conversations`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${secretKey}`
      }
    })
  }

  /**
   * On cache les conversations pour éviter d'avoir un nombre trop important sur la page
   */
  limitDisplayConversations() {
    let _ = this;
    let chatReserve = document.querySelector('.chat__reserve');

    if( _.convs.length > maxDisplayConvs) {
      let supConvs = _.convs.slice(0, _.convs.length - maxDisplayConvs);
      // let supConvs = _.convs.slice(maxDisplayConvs)

      // Create box for list conv
      if( !chatReserve ) {
        chatReserve = document.createElement('div')
        chatReserve.classList.add('chat__reserve');
        chatReserve.innerHTML = '<span class="chat__reserve--num">0</span><div class="chat__reserve--list"><ul></ul></div>';
        document.querySelector('.chat__private-convs').appendChild(chatReserve);
      }

      chatReserve.querySelector('.chat__reserve--num').innerHTML = `+${supConvs.length}`;
      let list = chatReserve.querySelector('.chat__reserve--list ul');
      list.innerHTML = '';

      // List all conv hidden
      supConvs.forEach(conv => {
        let li = document.createElement('li');
        li.setAttribute('id', 'conv-hidden-' + conv.internalId)

        let count = 0;
        conv.participants.forEach(p => {
          if( p.email != _.me.email ) {
            let name = p.nickname != '' ? p.nickname : p.email;

            if(count > 0 ) {
              name = ', ' + name;
            }

            li.innerHTML += name;
            count++;
          }
        })

        // delete conv in the list
        let convElt = document.getElementById('conv-' + conv.internalId);
        if( convElt ) convElt.remove();

        li.addEventListener('click', (e) => {
          e.preventDefault();
          let conversation = talkSession.getOrCreateConversation(conv.id);

          // Delete conv to re add 
          _.convs.forEach((c, i)  => {
            if( c.internalId == conv.internalId ) {
              _.convs.splice(i, 1);
              return false;
            }
          })
          _.createChatbox(conversation, true, '', 'before');
        })

        list.appendChild(li);
      })
    } else {
      if( chatReserve ) chatReserve.remove();
    }
  }

  /**
   * Listener sur les nouveaux messages entrant
   */
  unreadConversation() {
    let _ = this;
    
    // WHEN NEW MESSAGE IS SEND
    talkSession.unreads.on("change", function (conversationIds) {

      if(conversationIds) {
        // On récupère les conversations que l'utilisateur a supprimé 
        let deleteConvs = eval(Cookies.get("deleteConvs"));

        conversationIds.forEach(c => {
          let conv = c.lastMessage;
          
          let isNew = true;

          // Check if ME is recipient 
          if( _.convs && _.convs.length > 0 ) {
            _.convs.forEach((_c, i) => {
              // SI elle existe on averti du nouveau message
              if( _c.id == conv.conversation.id && conv.senderId != _.me.id && !deleteConvs.includes(conv.conversation.id) ) {
                let target = document.querySelector('#conv-' + _c.internalId);
                if( target ) target.classList.add('unread')

                _.updateDeletedConversations(_c);
                isNew = false;
                return false;
              }
            })
          } 

          // Si c'est une nouvelle conversation, on la créée chez les destinataires
          if( isNew ) {
            // On check si le message nous est destiné
            let participants = _.getParticipants(conv.conversation);

            if( participants) {
              participants.forEach(p => {
                if( p == _.me.email ) {
                  let conversation = talkSession.getOrCreateConversation(conv.conversation.id);
                  _.createChatbox(conversation, false, 'new');

                  return false;
                }
              })
            }
          }
          
        })
      }
    });
  }

  /**
   * Create button for add discussion
   */
  buttonConversation() {
    let _ = this;

    let addChat = document.querySelector('.chat__add');
    if( addChat ) {
      addChat.addEventListener('click', (e) => {
        e.preventDefault();

        HTML.classList.add('show-overlay');

        let users = [];
        let usersSelected = [];

        // Create popup
        let popup = document.querySelector('.chat__popup');
        let input = popup.querySelector('input');
        let close = popup.querySelector('.close');
        let header = popup.querySelector('.chat__popup--header')

        let btn = popup.querySelector('.btn')

        let ul = popup.querySelector('.list-users')
        ul.innerHTML = '';

        let chipsElt = popup.querySelectorAll('.user__selected');
        if( chipsElt ) {
          chipsElt.forEach(el => el.remove());
        }

        popup.classList.add('show')

        // On supprime la popup
        close.addEventListener('click', (e) => {
          e.preventDefault();
          HTML.classList.remove('show-overlay');
          popup.classList.remove('show');

          setTimeout(() => {
            popup.classList.remove('is-loaded');
          }, 450)
        })

        // Autocomplete
        input.addEventListener('keyup', (e) => {
          let val = input.value

          let list = ul.querySelectorAll('li');
          if( list ) {
            list.forEach((li) => {
              if( li.innerText.indexOf(val) < 0) {
                li.classList.add('hide')
              }  else {
                li.classList.remove('hide')
              }
            })
          }
        })

        // On créé la conversation
        btn.addEventListener('click', (e) => {
          e.preventDefault();

          if( usersSelected.length <= 0 ) return false;

          HTML.classList.remove('show-overlay');
          popup.classList.remove('show');
          _.createDiscussion(usersSelected);

          setTimeout(() => {
            popup.classList.remove('is-loaded');
          }, 450)
        })

        axios({
          method: 'get',
          url: `https://api.talkjs.com/v1/${appId}/users?isOnline=true&limit=100`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${secretKey}`
          }
        })
        .then(function (response) {
          if(response.data.data) {
            popup.classList.add('is-loaded')

            if(response.data.data.length <= 1 ) {
              let li = document.createElement('li');
              li.innerHTML = '<i>Aucun utilisateur est connecté.</i>';
              ul.appendChild(li)
              return false;
            }

            // Sort user on the conv
            response.data.data.forEach(user => {
              if( user.email[0] != _.opts.user.email &&
                  user.id.indexOf(_.opts.id_event) >= 0 ) {
                users.push(user);
              }
            });

            users.forEach(u => {
              let li = document.createElement('li');
              li.innerHTML += `<a href="#">${u.name}</a>`;
              ul.appendChild(li)

              li.addEventListener('click', (e) => {
                e.preventDefault();
                // On ajoute l'utilisateur dans le tableau memoire
                usersSelected.push(u);

                input.value = '';
                input.dispatchEvent(new Event('keyup'))

                // Ajout du badge 
                let chips = document.createElement('a');
                chips.innerHTML = u.name;
                chips.classList.add('user__selected')
                chips.addEventListener('click', (e) => {
                  // Si l'on supprime l'user
                  // on rajoute le user dans la liste
                  e.preventDefault();
                  chips.remove();
                  ul.appendChild(li);

                  // On supprime l'élément du tbaleau memoire
                  if( usersSelected.length > 0 ) {
                    usersSelected.forEach((el, i) => {
                      if ( el == u ) {
                        usersSelected.splice(i, 1);
                        return false;
                      }
                    })
                  }
                })
                header.insertBefore(chips, input)
                // On supprime l'item de la liste
                li.remove();
              })
            })
          }
        });
      })
    }
  }

  isNewConversation(conv) {
    let _ = this

    if( conv.id.indexOf(_.opts.id_event) >= 0 && conv.id.indexOf('hotline') < 0 ) {
      // Check if conv already exists
      if( _.convs && _.convs.length > 0 ) {
        let check = true;
        _.convs.forEach((c) => {
          if( c.id == conv.id ) {
            check = c.id;
            return false;
          } 
        })
        return check;
      } else {
        return true;
      }
    }

    return false;
  }

  /**
   * On traite l'id de la conversation pour en récupérant les participants
   * 
   * @param {ConversationBuilder} conv 
   */
  getParticipants(conv) {
    let id = conv.id;

    return id.replace(this.opts.id_event+'-', '').split('|');
  }

  getUsers(emails) {
    return axios({
      method: 'get',
      url: `${url}inc/.ajax.php?action=get-user&emails=${emails}`
    })
  }

  /**
   * On met à jour les cookies contenant les conversations supprimer par l'utilisateur
   * 
   * @param {Talk JS Conversation} conversation 
   */
  updateDeletedConversations(conversation) {
    // On récupère les conversations que l'utilisateur a supprimé 
    let deleteConvs = eval(Cookies.get("deleteConvs"));

    if( conversation.id) {

      // On supprime l'élement dans les conv supprimer
      deleteConvs.forEach((val, i)  => {
        if( val == conversation.id ) {
          deleteConvs.splice(i, 1);
          return false;
        }
      })
      Cookies.set('deleteConvs', deleteConvs);
    }
  }
}