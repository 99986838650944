const HTML = document.querySelector('html');

export default class Popup {
  constructor(content) {
    this.popup = document.createElement('div');
    this.popup.classList.add('popup');
    document.body.appendChild(this.popup);

    let inner = document.createElement('div');
    inner.classList.add('popup__content');
    inner.append(content);
    this.popup.append(inner);

    setTimeout(() => {
      this.popup.classList.add('show')
      HTML.classList.add('show-popup');
    })
  }

  close() {
    this.popup.classList.remove('show')
    HTML.classList.remove('show-popup');

    setTimeout(() => {
      this.popup.remove();
    }, parseFloat(getComputedStyle(this.popup)['transition-duration']) * 1000)
  }
}