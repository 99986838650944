import Plyr from 'plyr';
import gsap from 'gsap'
import {alert} from '../utils/utils';
import VimeoPlayer from '../utils/vimeo';
import Countdown from '../utils/countdown';
import axios from 'axios';

const HTML = document.querySelector('html')

export default class Login {
  constructor() {
		let _ = this;
		let login = document.querySelector('.login__panel');

		window.addEventListener('focus', () => {
			HTML.classList.add('focused')
		})

		// Add register form 
		const formRegister = document.querySelector('.form-register');
		if( formRegister ) this.registerForm(formRegister)
		
		let video = document.querySelector('.login__video');
		if( false && video ) {
			let v = document.querySelector('video');
			let volumeTrigger = video.querySelector('.volume');

			// Volume equalizer
			let bar = volumeTrigger.querySelector('.volume--bar') 
			bar.addEventListener('click', (e) => {
				e.preventDefault();

				v.muted = false;

				let pos = bar.getBoundingClientRect();
				let height = bar.offsetHeight;

				let vol = (pos.bottom - e.clientY) / height;
				v.volume = vol;

				gsap.set(bar.querySelector('span'), { scaleY : vol });
			});
	
			// Volume link muted
			volumeTrigger.querySelector('a').addEventListener('click', (e) => {
				e.preventDefault();
				volumeTrigger.classList.toggle('muted');
				v.muted = volumeTrigger.classList.contains('muted') ? true : false;
			})
		}


		// Add vimeo transition
		this.transition = document.querySelector('.transition');
		if( this.transition ) {
			this.transitionVideo = new VimeoPlayer(this.transition.querySelector('.transition__video'), this.transition, {
				id : this.transition.dataset.video,
				autoplay : false,
				features : {
					fullscreen : false,
					volume : true
				}
			})
	
			this.transitionVideo.on('ended', () => {
				setTimeout(() => {
					window.location.href = url_event + "main";
				}, 100)
			})
		}

		// Countdown
		let c = document.querySelector('.countdown')
		if( c ) {
			new Countdown(c, () => {
				if( video ) {
					let v = document.querySelector('video');
					v.play();
				}
	
				if( event['auth'] != 0 ) {
					HTML.classList.add('show-form');
	
					if( login ) {
						login.classList.add('active');
					}
				} else {
					_.goNext();
				}
			});

      if( login ) {
        login.classList.add('active');
      }
			// let daysEl = c.querySelector('.days span');
			// let hoursEl = c.querySelector('.hours span');
			// let minutesEl = c.querySelector('.minutes span');
			// let secondsEl = c.querySelector('.secondes span');
			// var countDownDate = c.dataset.date;

			// // Update the count down every 1 second
			// countdown();
			// var x = setInterval(countdown, 1000);

			// function countdown() {
			//   // Get today's date and time
			//   var now = new Date().getTime();

			//   // Find the distance between now and the count down date
			//   var distance = countDownDate - now;

			//   // Time calculations for days, hours, minutes and seconds
			//   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			//   var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			//   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      //   var seconds = Math.floor((distance % (1000 * 60)) / 1000);
				
			// 	if( distance <= 0 || bypass ) {
			// 		clearInterval(x);
			// 		if( video ) {
			// 			let v = document.querySelector('video');
			// 			v.play();
			// 		}

			// 		if( event['auth'] != 0 ) {
			// 			HTML.classList.add('show-form');

			// 			if( login ) {
			// 				login.classList.add('active');
			// 			}
			// 		} else {
			// 			_.goNext();
			// 		}
			// 	} else {
			// 		daysEl.innerHTML = days
			// 		hoursEl.innerHTML = (hours < 10 ? '0' : '') + hours;
			// 		minutesEl.innerHTML = (minutes < 10 ? '0' : '') + minutes;
			// 		secondsEl.innerHTML = (seconds < 10 ? '0' : '') + seconds;

			// 		if( login ) {
			// 			login.classList.add('active');
			// 		}
			// 	}
			// }
		}

		if( login ) {

			const loginForm = login.querySelector('.login');

			loginForm.addEventListener('submit', (e) => {
				let slug = loginForm.querySelector('[name="slug"]').value;
				let mail = loginForm.querySelector('[name="email"]').value;
				let pass = loginForm.querySelector('[name="pass"]').value;
				e.preventDefault();
				
				let form = new FormData();
				form.append('slug', slug)
				form.append('email', mail)
				form.append('password', pass)

				axios({
					method : 'post',
					url : `${url}inc/.ajax.php?action=check-login`,
					data : form
				})
				.then( response => {
					if( response.data && response.data.statut ) {

						if( video ) {
							let v = document.querySelector('video');
							v.pause()
						}
						// transition.classList.add('show')
						_.goNext();
					} else {
						alert(response.data.msg);
					}
				})

				return false;
			})
		}
	}

	goNext() {

		if( this.transition ) {
			this.transitionVideo.play();
			gsap.to(this.transition, { duration : 0.3, autoAlpha : 1});
		}
		else {
			window.location.href = url_event + "live";
		}
	}

	registerForm(form) {
		form.addEventListener('submit', (e) => {
			e.preventDefault();
			form.classList.add('sending');

			const formD = new FormData(form);
			
			const msg = document.createElement('div');
			msg.classList.add('msg')

			axios({
				method : 'post',
				url : `${url}inc/.ajax.php?action=register`,
				data : formD
			})
			.then(response => {
				form.classList.remove('sending')

				if( response && response.data ) {
					if( response.data.statut ) {
						msg.innerHTML = 'Merci pour votre inscription.';
						msg.classList.add('success')
						form.reset();
					} else if( response.data.msg ) {
						msg.innerHTML = response.data.msg;
						msg.classList.add('error')
					}
				} else {
					msg.innerHTML = 'Erreur lors de votre inscription';
					msg.classList.add('error')
				}

				form.prepend(msg);

				setTimeout(() => {
					msg.remove()
				}, 7000)
			})
			return false;
		})
	}
}