import axios from 'axios';
import Blazy from 'blazy'
import {alert} from './utils'
import Media from './media'

const HTML = document.querySelector('html');

export default class Sidebar {

  constructor(classes = '', callback = null) {
    let _ = this;

    this.callback = callback;

    // Add listener
    let links = document.querySelectorAll('[data-sidebar]:not(.event-init)');
    if( links ) {
      function click(e) {
        e.preventDefault();
        e.stopPropagation();
        
        HTML.classList.add('show-sidebar');

        axios({
          method : 'get',
          url : `${url}${this.dataset.sidebar}`
        })
        .then(response => {
          if(response.data) {
            // Get html response
            let html = document.createElement('div')
            html.innerHTML = response.data;

            // Get container ajax
            let container = html.querySelector('.app');
            if( !container) {
              alert('Erreur lors du chargement de cette page')
              return false;
            }

            const content = document.createElement('div');
            content.innerHTML = container.innerHTML;
            
            _.create(content,classes);
          } else {
            alert('Erreur lors du chargement de cette page')
          }
        })
        return false;
      }
      links.forEach(el => {
        el.classList.add('event-init')
        el.addEventListener('click', click)
      })
    }
  }

  create(content, classes = '') {
    let _ = this;
    
    // Construct sidebar
    let sidebar = document.createElement('div');
    sidebar.classList.add('sidebar');
    document.body.append(sidebar);

    if( classes != '' ) sidebar.classList.add(classes);
    
    let sidebarContent = document.createElement('div');
    sidebarContent.classList.add('sidebar__content');
    sidebarContent.append(content);
    sidebar.append(sidebarContent)

    HTML.classList.add('show-sidebar');


    const close = sidebar.querySelectorAll('.sidebar--close');
    if( close ) {

      function closeOverlay(e) {
        if( !e.target.classList.contains('sidebar') && !e.target.closest('.sidebar') ) {
          e.stopPropagation();
          e.preventDefault();
          
          if( close ) {
            close[0].dispatchEvent(new Event('click'));
          }
          
        }
      }

      HTML.addEventListener('click', closeOverlay);
      
      close.forEach(c => {
        c.addEventListener('click', e => {
          e.preventDefault();

          const parent = c.closest('.sidebar');

          parent.classList.remove('show');
          HTML.classList.remove('show-sidebar');
          HTML.removeEventListener('click', closeOverlay);

          const style = getComputedStyle(parent)
          const duration = parseFloat(style['transition-delay']) * 1000 + parseFloat(style['transition-duration']) * 1000

          setTimeout(() => {
            parent.remove();
          }, duration)
        })
      })
    }


    setTimeout(() => {

      sidebar.classList.add('show')

      setTimeout(() => {
        // Blazy
        _.blazySidebar = new Blazy({
          container: '.sidebar--content',
          breakpoints: [{
            width: 420,
            src: 'data-src-small' 
            }],
          selector : '.js-lazy',
          success : function(elt) {
            if( elt.closest('figure') )
              elt.closest('figure').classList.add('img-is-loaded')
          }
        });
      }, 400)

      if( _.callback ) _.callback.call(sidebar);

      // Autoscroll media
      const medias = sidebar.querySelector('.media__list');
      if( medias ) {
        const m = new Media();

        m.infiniteScroll(sidebar.querySelector('.sidebar--content'));
      }
    }, 100);
  }
}