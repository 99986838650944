import Sidebar from '../utils/sidebar'

export default class Body {
  constructor(elt, opts, chat = null) {

    this.opts = opts;

    const sidebar = new Sidebar();

    sidebar.create(elt.children[0]);
  }

  destroy() {
  }
}