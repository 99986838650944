export default class Shortcut {
  constructor(elt, opts, chat = null) {

    this.opts = opts;

    // Raccourci pour créer un chat dédié ONE TO ONE
    if( this.opts.shortcut == 'chat' && this.opts.shortcutChat != '' ) {
      this.createChat(chat);
    }
  }

  createChat(chat) {

    chat.getUsers([this.opts.shortcutChat]).then(response => {
      if( response.data ) {
        let exist = false;

        const u = response.data[0];
        
        const idConv = this.opts.id_event + '-' + chat.me.email + '|' + u.email

        if( chat.convs ) {
          chat.convs.forEach(c => {
            if( c.id == idConv ) {
              const conv = document.querySelector(`#conv-${c.internalId}`)
              if( !conv.classList.add('show') ) {
                conv.dispatchEvent(new Event('click'));
              }
              exist = true
              return;
            }
          })
        }

        if( !exist ) {
          const user = new Talk.User({
            id: this.opts.id_event + '_' + u.email,
            name: u.nickname,
            email: u.email,
            photoUrl : u.avatar,
            role: "timeline",
            welcomeMessage: (this.opts.event.slug == 'icncampus' ? "Bonjour, en quoi puis-je vous aider ? \n Hello, how can I help you ?" : "Bonjour, en quoi puis-je vous aider ?")
          });;
          const conversation = window.talkSession.getOrCreateConversation(idConv);
          conversation.setParticipant(chat.me);
          conversation.setParticipant(user); 
  
          chat.createChatbox(conversation);
        }
      }
    })
  }

  destroy() {
  }
}