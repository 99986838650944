import VimeoPlayer from '../utils/vimeo'

export default class Video {
  constructor(elt, opts, chat = null) {

    this.opts = opts;

    if( opts.urlVideo ) {
      // Create player vimeo
      let videoParams = {}

      if( opts.urlVideo.indexOf('http') >= 0 ) {
        videoParams = {
          id : opts.urlVideo
        }
      } else {
        videoParams = {
          url : opts.urlVideo
        }
      }

      videoParams.autoplay = true;
      videoParams.adaptive = true;
      videoParams.fullscreen = false;
      videoParams.features = {
        fullscreen : false,
        volume : true
      };
      
      let container = elt.querySelector('.workshop-video__container');

      this.player = new VimeoPlayer(container.querySelector('.inner'), container, videoParams)
    }

    if( opts.activeChat) {
      // Create chat 
      chat.joinChat(this.opts.user, `${this.opts.event}-video-${this.opts.workshopId}`, '.workshop-video__chat', this.opts.workshopName);
    }

  }

  destroy() {
    if( this.player ) {
      this.player.pause();
    }
  }
}