import axios from 'axios';
import Plyr from 'plyr';

const HTML = document.querySelector('html')

const INTERVAL_REQUEST = 30000;

export default class Medias {
  constructor(opts) {
    let _ = this;
    this.opts = opts;    

    this.handleMedia(document)
  }

  /**
   * All  medias
   */
  socialWall() {
    let medias = document.querySelectorAll('.media__list')
    
    if( medias ) {
      medias.forEach(media => {
        const limit = media.dataset.limit ? media.dataset.limit : 6
        let exclude = '';
        if( media.dataset.media == 'spik' ) {
          exclude = 'gif';
        } else if( media.dataset.media == 'giforama') {
          exclude = 'video';
        }
        this.autoloadMedia(media, limit, exclude, media.dataset.media);
      })
    }
  }

  /**
   * Open lightbox when click on media video
   * 
   * @param {HTML element} medias 
   */
  handleMedia(medias) {
    // Medias element
    const elts = medias.querySelectorAll('[data-type="video"]:not(.handle-init)');
    if( elts ) {
      elts.forEach(el => {
        el.classList.add('handle-init')

        el.addEventListener('click', e => {

          const video = el.querySelector('video');
          if( video ) {
            e.preventDefault();
            e.stopPropagation();
            this.lightbox(video.dataset.url)
          }
        })
      })
    }
  }

  /**
   * Display new media
   * 
   * @param {HTML element} elt the element which inser the new media
   */
  autoloadMedia(elt, limit = 0, exclude_type = 'video', type = 'gif') {
    let _ = this;
    let selector = '.media';
    let baseMedia = elt.querySelector(`${selector}.base`);

    axios({
      method: 'get',
      url: `${url}inc/.ajax.php?action=get-medias&id_event=${event.id}&limit=${limit}&exclude_type=${exclude_type}&type=${type}`
    })
    .then(function (response) {
      if( response.data) {
        response.data.forEach( media => {

          let inSocialWall = elt.querySelector(`#media-${media['id']}`);
          if( inSocialWall ) {
            return false;
          }

          // Build the new media
          let newMedia = _.createMedia(media, baseMedia);

          elt.prepend(newMedia);
          let medias = elt.querySelectorAll(`${selector}:not(.base)`);
          // window.MainApp.blazy.revalidate();

          if( limit > 0 && medias.length > limit ) {
            for(var i = medias.length-1; i > limit-1; i-- ) {
              medias[i].remove();
            }
          }
        })
      }
        
      setTimeout(() => {
        _.autoloadMedia(elt, limit, exclude_type, type);
      }, INTERVAL_REQUEST)
    })
    .catch((error) => {        
      setTimeout(() => {
        _.autoloadMedia(elt, limit, exclude_type, type);
      }, INTERVAL_REQUEST)
    });
  }

  infiniteScroll(container) {
    const _ = this;
    const medias = container.querySelector('.media__list');
    let baseMedia = medias.querySelector(`.base`);

    function scroll(e) {
      const bound = medias.getBoundingClientRect();
      const height = container.offsetHeight
 
      if( container.scrollTop + height >= bound.height * .9) {
        container.removeEventListener('scroll', scroll);
        medias.classList.add('onload');

        let offset = parseInt(medias.dataset.offset) + parseInt(medias.dataset.limit);

        axios({
          method: 'get',
          url: `${url}inc/.ajax.php`,
          params : {
            'action' : 'get-medias',
            'id_event' : medias.dataset.event,
            'offset'  : offset,
            'limit' : medias.dataset.limit,
            'type' : medias.dataset.type,
            'exclude_type' : medias.dataset.excludeType
          }
        })
        .then(response => {

          if(response.data && response.data.length > 0 ) {
            medias.dataset.offset = offset;

            response.data.forEach(m => {
              const media = _.createMedia(m, baseMedia)
              medias.append(media);
            })

            setTimeout(() => {
              container.addEventListener('scroll', scroll);
            }, 400);
          }

          medias.classList.remove('onload');
        })
      }
    }
    container.addEventListener('scroll', scroll);
  }

  createMedia(data, base) {
    const _ = this;
    const media = base.cloneNode(true);
    media.classList.remove('base');
    media.setAttribute('id', `media-${data['id']}`);
    media.setAttribute('data-type', data.type);

    // If video 
    if( data.type == 'video' ) {
      let video = media.querySelector('video');
      let url = data['url'][0];
      let poster = url.replace('upload/', 'upload/w_700,so_6,du_1/');
      var source = document.createElement('source');
      let thumb = url.replace('upload/', 'upload/w_400,so_6,du_3/');
      
      // if( this.opts.event.slug == 'acm-50ans') thumb = url.replace('upload/', 'upload/w_400,so_3.1,du_3/');
      source.src = thumb;
      source.type = 'type/mp4'
      video.poster = poster.replace('.mp4', '.png');
      video.src = thumb
      video.muted = true

      const img = media.querySelector('img');
      if( img ) img.remove();

      media.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        _.lightbox(url);
      })

      // _.playerVideo([video]);
    } 
    else 
    {
      const video = media.querySelector('video');
      if( video ) video.remove();

      let img = media.querySelector('img')
      img.setAttribute('src', data['url'][0]);
      // img.setAttribute('data-src', data['url'][0]);
      // img.setAttribute('src', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
      // img.classList.remove('b-loaded');
    }

    return media;
  }

  lightbox(src) {
    const lightbox = document.createElement('div');
    lightbox.classList.add('lightbox');

    const container = document.createElement('div');
    container.classList.add('lightbox__container');
    lightbox.append(container);

    const content = document.createElement('div');
    content.classList.add('lightbox__content');
    container.append(content);

    const close = document.createElement('a');
    close.classList.add('close', 'btn-rounded');
    close.innerHTML = '<i class="icon--cross"><span></span><span></span></i>'
    container.append(close);

    // 
    if( src.indexOf('.mp4') !== false ) {
      const video = document.createElement('video');
      content.append(video);

      video.innerHTML = `<source src="${src}" type="video/mp4" />`;

			const player = new Plyr(video, {
        controls : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        autoplay: true
      });
      
    }
    
    document.body.dispatchEvent(new Event('mute'));
    document.body.append(lightbox)

    function closeHandle(e) {
      e.preventDefault();
      if( !e.target.classList.contains('lightbox__container') && !e.target.closest('.lightbox__container') ) {
        e.stopPropagation();
        e.preventDefault();

        close.dispatchEvent(new Event('click'));
      }
    }

    HTML.addEventListener('click', closeHandle)

    close.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      lightbox.classList.remove('show');
      HTML.removeEventListener('click', closeHandle);

      // document.body.dispatchEvent(new Event('unmute'));

      const style = getComputedStyle(lightbox)
      const duration = parseFloat(style['transition-delay']) * 1000 + parseFloat(style['transition-duration']) * 1000

      setTimeout(() => {
        lightbox.remove();
      }, duration)
    })

    setTimeout(() => {
      lightbox.classList.add('show')
    }, 200);
  }
}