import axios from 'axios';
import gsap from 'gsap';
import Mainstage from './mainstage';
import Chat from '../vendors/chat';
import VimeoPlayer from '../utils/vimeo';
import Popup from '../utils/popup';
import Sidebar from '../utils/sidebar';
import YTPlayer from 'yt-player';
import { data } from 'flickity';

const HTML = document.querySelector('html');

export default class Live {
  constructor(opts) {
    let _ = this;

    _.state = 'live';
    
    this.opts = opts;
    
    this.opts.id_event = 'timeline_' + this.opts.event.slug ;
    
    // Create chat
    this.chat = new Chat(this.opts);
    let chatElt = document.querySelector('.chat')
    if( chatElt ) {
  
      // Join the general chat
      this.chat.joinChat(this.opts.user, this.opts.event.slug).then(() => {
        // On récupère toutes les conversations hors conv général
        this.chat.getAllConversations();
  
        // Listener sur les nouveaux conv
        this.chat.unreadConversation();
  
        // Create button for add chat
        this.chat.buttonConversation();

        // Create hotline
        this.buildHotline();
      })
    } else {
      _.buildHotline();
    }

    // Create module question
    let question = document.querySelector('.question-module');
    if( question ) {
      this.buildQuestionModule(question);
    }

    // Tuto step
    if( event['auth'] == 1) {
      this.tutoPanel();
    } else {
      _.handleState();
    }

    // Init sidebar
    new Sidebar();
  }

  handleState() {
    let _ = this;
    let panelActive = document.querySelector('.panel.active');
    let panelLive = document.querySelector('.live__panel');
    let panelAfter = document.querySelector('.after__panel');

    axios({
      method: 'get',
      url: `${url}inc/.ajax.php?action=state&id=${event.id}`
    })
    .then(function (response) {

      if( response.data.output && response.data.output == 'mainstage' ) {
        if( _.state != 'mainstage') {
          new Mainstage(_.opts, _.chat);

          if( _.liveplayer ) {
            _.liveplayer.pause()
          }
  
          panelActive.classList.remove('active');
          panelAfter.classList.add('active');
        }
      } 
      else if( response.data.output && response.data.output == 'after' ) {
        window.location.href =`${url_event}after`;
      }
      else {

        if( !_.liveplayer ) {
          // Create live
          _.createLive();

          panelActive.classList.remove('active');
          panelLive.classList.add('active');
        }
      }
      _.state = response.data.output
    });
  } 

  buildHotline() {
    let _ = this;
  
    // Create chat hotline
    let hotline = document.querySelector('.hotline');
    if( hotline ) {
      this.chat.hotline(hotline.querySelector('.hotline__chat'));

      // Trigger chat
      hotline.querySelector('.hotline--trigger').addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        HTML.removeEventListener('click', toggleHotline)

        HTML.classList.toggle('show-hotline')
        HTML.addEventListener('click', toggleHotline)
      })

      function toggleHotline(e) {
        if( HTML.classList.contains('show-hotline') ) {
          if( !e.target.classList.contains('hotline') && !e.target.closest('.hotline') ) {
            e.preventDefault();
            HTML.classList.remove('show-hotline')
            HTML.removeEventListener('click', toggleHotline)
          }
        }
      }
    }
  }

  buildQuestionModule(el) {
    const form = el.querySelector('form')
    if( form ) {
      const trigger = el.querySelector('.question-module__trigger')
      if( trigger ) {
        trigger.addEventListener('click', (e) => {
          e.preventDefault();

          el.classList.toggle('show')
        })
      }
      const close = el.querySelector('.trigger-close');
      if( close ) {
        close.addEventListener('click', (e) => {
          e.preventDefault();

          el.classList.remove('show')
        })
      }

      let textarea = form.querySelector('textarea');
      if( textarea ) {
        let notice = textarea.nextElementSibling;
        if( notice && notice.classList.contains('notice')) {
          let chars = notice.querySelector('.nb-chars');
          let limit = parseInt(chars.dataset.limit)
          textarea.addEventListener('keydown', e => {
            // if(e.keyCode == 8 || e.keyCode == 13 )
            if( textarea.value.length >= limit && e.keyCode != 8 ) {
              e.preventDefault();
              textarea.value = textarea.value.substring(0, limit);
            }

            chars.innerHTML = limit - textarea.value.length
          })
        }
      }


      form.addEventListener('submit', (e) => {
        e.preventDefault();

        let msgs = form.querySelectorAll('.msg')
        if( msgs ) {
          msgs.forEach(m => {
            m.remove();
          })
        }

        form.classList.add('onloading');
        const values = new FormData(e.target);

        let msg = document.createElement('div');
        msg.classList.add('msg')

        if(values.get('nom') == '' || values.get('question') == '' ) {
          msg.classList.add('error');
          msg.innerHTML = "Les champs marqués (*) sont obligatoire."
          return false;
        }

        axios({
          method: 'post',
          data : values,
          url: `${url}inc/.ajax.php?action=post-question`
        })
        .then(function (response) {
          form.prepend(msg);
          form.classList.remove('onloading');

          if( response.data) {
            if( response.data.statut ) {
              msg.classList.add('success')
              msg.innerHTML = 'Votre question a bien été envoyé.'

              form.reset();
              if( textarea ) {
                let notice = textarea.nextElementSibling;
                if( notice && notice.classList.contains('notice')) {
                  let chars = notice.querySelector('.nb-chars');
                  let limit = parseInt(chars.dataset.limit)
                  chars.innerHTML = limit;
                }
              }
            } else {
              msg.classList.add('error')
              msg.innerHTML = response.data.msg;
            }
          } else {
            msg.classList.add('error')
            msg.innerHTML = "Une erreure s'est produite, merci de réessayer ultérieurement."
          }

          setTimeout(() => {
            msg.remove();
          }, 5000);
        })
        .catch((response) => {
          form.classList.remove('onloading');
        })

        return false;
      })
    }
  }

  /**
   * Init event on tuto panel (intro panel)
   */
  tutoPanel() {
    let _ = this;

    // Trigger continue
    let triggerContinue = document.querySelector('.trigger-continue');
    if( triggerContinue ) {
      triggerContinue.addEventListener('click', (e) => {
        e.preventDefault();
        HTML.classList.add('show-chat')

        _.hideChat()

        // Check handleState live
        _.handleState();
        this.timerState = setInterval(() => {
          _.handleState();
        }, 10000)
      })
    }

    // Create popup tuto
    let triggerTuto = document.querySelector('.trigger-tuto');
    if( triggerTuto ) {
      triggerTuto.addEventListener('click', (e) => {
        e.preventDefault();

        // Create video tuto
        let tuto = document.createElement('div');
        tuto.classList.add('tuto', 'u-tac');
        let tutoContent = document.createElement('div');
        tutoContent.classList.add('tuto__content')
        tuto.append(tutoContent);

        let player = new VimeoPlayer(tutoContent, tuto, {
          id : triggerTuto.dataset.video,
          fullscreen: false,
          autoplay: true,
          width : '100%',
          controls : true
        });

        // Create popup
        let popup = new Popup(tuto)

        // Create button skip
        if( triggerContinue ) {
          let skip = document.createElement('a')
          skip.classList.add('btn');
          skip.innerHTML = 'Passer le tuto';
          tuto.append(skip);

          skip.addEventListener('click', e => {
            e.preventDefault();

            triggerContinue.dispatchEvent(new Event('click'));
            player.pause();
            popup.close()
            _.hideChat()
          })

          player.on('ended', () => {
            skip.dispatchEvent(new Event('click'));
            _.hideChat()
          })
        }
      })
    }
  }

  /**
   * Generate iframe youtube player
   */
  createLive() {
    let _ = this;
    let app = document.querySelector('.app');
    let live = app.querySelector('.live__panel');

    _.liveplayer = new VimeoPlayer(document.querySelector('#player'), live, {
      id : _.opts.idLive,
      tooltip: true,
      autoplay: "autoplay" 
    })

    // Multilingue
    this.multlingueLive();
  }

  /**
   * Hide the chat panel - init the trigger chat panel
   */
  hideChat() {
    let chatMain = document.querySelector('.chat__main');
    if( chatMain ) {
      setTimeout(()=> {
        chatMain.classList.add('hide-chat')
      }, 1500)

      // Trigger chat
      let trigger = chatMain.querySelector('.chat--trigger');
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation()

        chatMain.classList.toggle('hide-chat')
      })
    }
  }

  /**
   * Multlingue switch YT
   */
  multlingueLive() {
    const _ = this;
    const yt = document.querySelector('#ytplayer')
    const vimeo = document.querySelector('#player');
    let isFullescreen = true;

    console.log(yt); 

    if( yt ) {
      const player = new YTPlayer(yt.querySelector('.content'), {
        autoplay : true,
        controls: false,
      })

      if( yt.dataset.videoid ) {
         player.load(yt.dataset.videoid)
         player.setVolume(100);
         player.play();
         player.mute();

        const width = parseFloat(yt.dataset.width)
        const height = parseFloat(yt.dataset.height)
        const ratioVideo = width / height;
        let currentLang = 'fr';


        window.addEventListener('resize.fullscreen', () => {
          const hW = window.innerHeight
          const wW = window.innerWidth
          let wNew = 0;
          let hNew = 0;

          if( isFullescreen ) {
            wNew = ratioVideo > wW/hW ? width * hW / height : wW;
            hNew = ratioVideo > wW/hW ? hW : height * wW / width;
            
            isFullescreen = false;
          } else {
            wNew = wW * 0.7;
            hNew = wNew * height / width

            isFullescreen = true;
          }

          player.setSize(wNew, hNew);
        })

        // Volume controls
        const volumeControls = document.querySelector('.volume');
        if( volumeControls ) {
          volumeControls.querySelector('a').addEventListener('click', e => {
            if( currentLang == 'de') {
              _.liveplayer.mute(false);
              volumeControls.classList.toggle('muted');

              if( volumeControls.classList.contains('muted')) {
                player.mute();
              } else {
                  player.unMute();
              }
            }
          })

          const bar = document.querySelector('.volume--bar');
          bar.addEventListener('click', (e) => {
            if( currentLang == 'de') {
              _.liveplayer.mute(false);
      
              let pos = bar.getBoundingClientRect();
              let height = bar.offsetHeight;
              
              let vol = (pos.bottom - e.clientY) / height;
              player.setVolume(vol * 100);
              gsap.set(bar.querySelector('span'), { scaleY : vol });
            }
          })
        }
      }
    }
  }
}