import axios from 'axios';
import gsap from 'gsap';
import VimeoPlayer from '../utils/vimeo';
import YTPlayer from 'yt-player';

const HTML = document.querySelector('html');

export default class Live {
  constructor(opts) {
    let _ = this;    
    this.opts = opts;
  }

  init() {

    // Create live
    this.createLive();
  }

  load() {
    const _ = this;

    delete _.opts.chat;

    return new Promise((resolve, reject) => {
    
      // Load the tpl
      axios({
        method: 'get',
        url: `${url}inc/modules/live.php`,
        params : {
          id_event : _.opts.event.id,
          id_module : _.opts.module.id,
        }
      })
      .then((response) => {
        if( response.data ) {
          const el = document.createElement('div');
          el.innerHTML = response.data;

          _.panel = el.children[0];
          resolve(_.panel);
        }
      })
      .catch(() => {
        reject();
      })

    })
  }

  /**
   * Generate iframe youtube player
   */
  createLive() {
    let _ = this;

    const player = document.querySelector('#player');
    if( player ) {
      _.liveplayer = new VimeoPlayer(player, this.panel, {
        id : _.opts.module.live,
        tooltip: true,
        autoplay: "autoplay",
      })

    }

    const btnFullscreen = document.querySelector('.trigger-video-fullscreen')
    if( btnFullscreen) {
      if( _.opts.event.slug == 'wienerbergertousengages') {
      }
      btnFullscreen.dispatchEvent(new Event('click'));
    }

    // Multilingue
    this.multlingueLive();
  }

  /**
   * Multlingue switch YT
   */
  multlingueLive() {
    const _ = this;
    const yt = document.querySelector('#ytplayer')
    const vimeo = document.querySelector('#player');
    let isFullescreen = true;

    if( yt ) {
      const player = new YTPlayer(yt.querySelector('.content'), {
        autoplay : true,
        controls: false,
      })

      if( yt.dataset.videoid ) {
         player.load(yt.dataset.videoid)
         player.setVolume(100);
         player.play();
         player.mute();

        const width = parseFloat(yt.dataset.width)
        const height = parseFloat(yt.dataset.height)
        const ratioVideo = width / height;
        let currentLang = 'fr';

        // build selector lang
        const controls = document.querySelector('.video__controls');

        const selector = document.createElement('div');
        selector.classList.add('selector');
        controls.prepend(selector);

        const list = document.createElement('ul');
        selector.append(list);

        const current = document.createElement('a')
        current.classList.add('btn-rounded', '-border')
        current.innerHTML = currentLang;
        selector.append(current)

        const langs = yt.dataset.langs.split(',');
        langs.push('fr');
        langs.forEach(lang => {
          const el = document.createElement('li');
          el.classList.add('btn-rounded', '-border')
          el.innerHTML = '<span>' + lang + '</span>';
          el.dataset.lang = lang;

          if( lang == currentLang ) el.classList.add('active')

          list.append(el);

            // Change lang
          el.addEventListener('click', e => {
            e.preventDefault();
  
            const active = el.closest('.selector').querySelector('.active');
            if(active) {
              active.classList.remove('active')
            }
  
            el.classList.add('active');

            currentLang = el.dataset.lang;
            current.innerHTML = currentLang;

            gsap.set(yt, { autoAlpha : el.dataset.lang == 'de' ? 1 : 0 })
            gsap.set(vimeo, { autoAlpha : el.dataset.lang == 'de' ? 0 : 1 })

            volumeControls.classList.remove('muted')

            const bar = document.querySelector('.volume--bar'); 
            if (bar) {
              gsap.set(bar.querySelector('span'), { scaleY : 1 });
            }

            // Mute and unmute selected video
            if( el.dataset.lang == 'de' ) {
              player.unMute()
              _.liveplayer.mute(false);
            } else {
              player.mute()
              _.liveplayer.unmute(false);
            }
          })
        })

        window.addEventListener('resize.fullscreen', () => {
          const hW = window.innerHeight
          const wW = window.innerWidth
          let wNew = 0;
          let hNew = 0;

          if( isFullescreen ) {
            wNew = ratioVideo > wW/hW ? width * hW / height : wW;
            hNew = ratioVideo > wW/hW ? hW : height * wW / width;
            
            isFullescreen = false;
          } else {
            wNew = wW * 0.7;
            hNew = wNew * height / width

            isFullescreen = true;
          }

          player.setSize(wNew, hNew);
        })

        // Volume controls
        const volumeControls = document.querySelector('.volume');
        if( volumeControls ) {
          volumeControls.querySelector('a').addEventListener('click', e => {
            if( currentLang == 'de') {
              _.liveplayer.mute(false);
              volumeControls.classList.toggle('muted');

              if( volumeControls.classList.contains('muted')) {
                player.mute();
              } else {
                  player.unMute();
              }
            }
          })

          const bar = document.querySelector('.volume--bar');
          bar.addEventListener('click', (e) => {
            if( currentLang == 'de') {
              _.liveplayer.mute(false);
      
              let pos = bar.getBoundingClientRect();
              let height = bar.offsetHeight;
              
              let vol = (pos.bottom - e.clientY) / height;
              player.setVolume(vol * 100);
              gsap.set(bar.querySelector('span'), { scaleY : vol });
            }
          })
        }
      }
    }
  }
}