import * as PIXI from 'pixi.js'
import gsap from 'gsap'
import GIF from 'gif.js'
import axios from 'axios';
import {alert} from '../utils/utils'

const frameMax = 1;
const frameInterval = 100;
const HTML = document.getElementsByTagName('html')[0]

export default class Photomaton {

  constructor(elt, opts) {
		let _ = this
		_.opts = opts;
		
		this.loader = document.querySelector('.giforama__loader');
    
		this.options = {
			selector : '.giforama .panel',
			current : -1,
			liveview : {
				width : 800,
				height : 800
			},
			video : {
				width : 1280,
				height : 720
			}
		}

		this.options.steps = document.querySelectorAll(this.options.selector).length

		this.init();
		
    // ---------------------
    // Create liveview panel
		// ---------------------
		this.panelLiveview()
		
    // // ---------------------
    // // Create preview panel
		// // ---------------------
		this.panelPreview()
		
    // // ---------------------
    // // Go on first panel
    // // ---------------------
		this.goto(0);
	}

	init() {
		let _ = this;

		_.media = {
			id : Date.now(),
			gif : '',
			email: user.email,
			save : 0,
			type : 'photo',
			event: event.id,
			screen : []
		}

		// Clear form
		let inputs = document.querySelectorAll('input');
		if( inputs ) {
			inputs.forEach((el) => {
				el.value = '';
			})
		}
	}
	
	createLiveview(elt) {
		let _ = this

		let link = document.querySelectorAll('a[disabled]');

		let wCanvas = this.options.liveview.width
		let hCanvas = this.options.liveview.height 
		let wCamera = this.options.video.width
		let hCamera = this.options.video.height 
		
		_.cLive = new PIXI.Application({ width: wCanvas, height: hCanvas });
		
		_.stage = new PIXI.Container();
		_.template = new PIXI.Container();
		_.cLive.stage.addChild(_.stage)
		_.cLive.stage.addChild(_.template)

		_.videoSprite = new PIXI.Sprite();
		_.stage.addChild(_.videoSprite);

		elt.append(_.cLive.view);

		let camera = document.createElement('video')

		navigator.mediaDevices.getUserMedia({
			audio : false,
			video: {
		    width: { ideal: wCamera },
		    height: { ideal: hCamera }
			}
		}).then(function(stream) {
			if( link ) {
				link.forEach((el) => {
					el.removeAttribute('disabled')
				})

				document.querySelector('.notice-required').remove();
			}

			_.stream = stream
			camera.srcObject = _.stream
			// camera.playsinline = true
			// camera.muted = true
			camera.captureStream = camera.captureStream || camera.mozCaptureStream;
			let {width, height} = stream.getTracks()[0].getSettings();

		  if( width != null && height != null ) {
				wCamera = width;
				hCamera = height;
				_.options.video.width = wCamera;
				_.options.video.height = hCamera;
		  }
    
			let texture = PIXI.Texture.from(camera);

			let newW = wCamera > hCamera ? wCamera * hCanvas / hCamera : wCanvas;
			let newH = wCamera > hCamera ? hCanvas : hCamera * wCanvas / wCamera;

			_.videoSprite.texture = texture
			_.videoSprite.anchor.set(0.5);
			_.videoSprite.scale.x = -1
			_.videoSprite.x = wCanvas / 2
			_.videoSprite.y = hCanvas / 2;
			_.videoSprite.width = newW;
			_.videoSprite.height = newH;

			if( _.opts.gabarits ) {
				let templates = JSON.parse(_.opts.gabarits);

				// Add gabarit (habillage)
				let loader = new PIXI.Loader();
				for(var i = 0; i < templates.length; i++) {
					loader.add('gif' + i, templates[i])
				}
				loader.load((loader, resources) => {
					let frames = []
					for(var i = 0; i < frameMax; i++) {
						frames.push(resources['gif' + i].texture);
					}
	
					const anim = new PIXI.AnimatedSprite(frames);
					anim.x = wCanvas / 2;
					anim.y = hCanvas / 2;
					anim.width = wCanvas;
					anim.height = hCanvas;
					anim.anchor.set(0.5);
					anim.animationSpeed = frameInterval / 1000;
					anim.play();
	
					_.template.addChild(anim);
				});
			}
		});
	}

	addPreview( ) {
		let _ = this;

		return new Promise((resolve, reject) => {
			let panel = document.querySelector('[data-panel="preview"]');
			const byteCharacters = atob(_.media.screen[0].split(',')[1]);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], {type: 'audio/mp3'});
			_.media.gif = blob

			let img = panel.querySelector('.preview__img');

			let gif = document.createElement('img');
			gif.src = _.media.screen[0];

			gif.onload = function() {
				img.append(gif);
				resolve()
			}

			// Dl button
			let dl = panel.querySelector('.trigger-dl');
			if( dl ) {
				dl.setAttribute('href', _.media.screen[0])
			}
		})
	}

	goto(index, callback = null) {
		let _ = this
		let elts = document.querySelectorAll(this.options.selector);
		let currentElt = elts[this.options.current];
		let nextElt = elts[index];

		let dir = index > this.options.current ? 1 : -1;

		if( currentElt != undefined )
		{
			gsap.to(
				currentElt, 
				{ 
					duration : 0.5,
					x : (-100 * dir) + '%', 
					ease : "circ.inOut"
				}
			)
		}

		gsap.fromTo(
			nextElt, 
			{ 
				autoAlpha : 1, 
				x : (100 * dir) + '%'
			}, 
			{ 
				duration : 0.5,
				x : '0%', 
				delay : 0.1, 
				ease : "circ.inOut",
				onComplete : function() {
					if( typeof callback == 'function') {
						callback.call();
					}

					if( index == _.options.steps - 1) {
						_.finish();
					}
				} 
			}
		)


		this.options.current = index;
	}

	finish() {
		let _ = this;

		let form = new FormData();

		for (let [key, value] of Object.entries(_.media)) {
			form.append(key, value);
		}

		axios({
			method : 'post',
			url: `${url}/inc/.ajax.php?action=save-media`,
			headers: { 'Content-Type': 'multipart/form-data' },
			data : form
		}).then((response) => {
			if( response.data ) {
				if( !response.data.statut) {
					alert(response.data.msg);
				} 
				_.goto(0);
			}
		}).then(() => {

		})
	}

	/** ================================================= */
	/** ================================================= */
	/** ================================================= */
	/** ====== PANELS ======= */
	/** ================================================= */
	/** ================================================= */
	/** ================================================= */

	panelLiveview() {
		let _ = this;
		let panel = document.querySelector('[data-panel="liveview"]');
		let countdown = panel.querySelector('.camera--countdown');
		let flash = document.querySelector('.giforama__flash')

		_.createLiveview(panel.querySelector('.canvas'))
		
		// Construct live view tl
		_.tlStart = gsap.timeline({ paused : true, onComplete : function() {

			_.videoSprite.scale.x *= -1
			_.goto(_.options.current+1, function() {
				gsap.set(panel, { clearProps : 'all' });

				// Clear all props
				_.tlStart.pause().seek(0)
			});
		 }})

		let durationFlash = 0.15;
		 _.tlStart
		 		.to(
					 panel.querySelector('.action'),
					 {
						 duration : 0.3,
						 autoAlpha : 0
					 }, 'start'
				 )
				.fromTo(
					countdown.querySelectorAll('span'),
					{
						y : '-60%', 
						autoAlpha : 0
					},
					{ 
						duration: 0.45, 
						stagger : 1,
						y : '-50%', 
						autoAlpha : 1
					}, 'start+=.2')
				.to(
					countdown.querySelectorAll('span'), 
					{ 
						duration: 0.45, 
						stagger: 1,
						y : '-40%', 
						autoAlpha : 0 
					}, 'start+=.65')
				.to(
					panel.querySelectorAll('.canvas, .content'), 
					{ 
						duration: 0.4,
						autoAlpha : 0 ,
						onComplete : () => {
							// On retourne le retour video pour avoir l'image droite
							_.videoSprite.scale.x *= -1
						}
					})
				.to(
					flash,
					{
						duration: 0.1,
						autoAlpha : 1,
						onComplete : () => {
							// On prend un screenshot du canvas
							_.media.screen.push(_.cLive.renderer.view.toDataURL('image/png', 1.0))
							_.addPreview();
						}
					}, 'take-picture+=0.2'
				)
				.to(
					flash, 
					{
						duration : 0.1,
						autoAlpha : 0,
					}, 'take-picture+=0.3'
				)

		// Start the itw
		panel.querySelector('.trigger-start').addEventListener('click', (e) => {
			e.preventDefault();
			let cTime = _.tlStart.time();
			clearTimeout(_.timerPause);
			if(cTime == 0 || cTime == _.tlStart.duration()) {
				_.tlStart.seek(0).play();
				_.media.id = Date.now();

				// Init default infos
				_.init()
			}
		})
	} 

	panelPreview() {
		let _ = this;
		let panel = document.querySelector('[data-panel="preview"]');

		let btnRestart = panel.querySelector('.trigger-restart')
		let btnContinue = panel.querySelector('.trigger-continue')
		let imgContainer = panel.querySelector('.preview__img')

		// Restart 
		btnRestart.addEventListener('click', (e) => {
			e.preventDefault();

			// Go to first panel
			_.goto(0, reinitPanel);
		})

		// Continue 
		btnContinue.addEventListener('click', (e) => {
			e.preventDefault();

			// Go to first panel
			_.goto(_.options.current + 1, reinitPanel);
		})

		function reinitPanel() {
			imgContainer.innerHTML = '';
		}
	}	

	destroy() {

	}
}