export default class Countdown {

  constructor(elt, callback = false) {
    this.elt = elt;
    this.callback = callback

    this.countdown();
    this.x = setInterval(() => { this.countdown() }, 1000);
  }

  countdown() {
    let daysEl = this.elt.querySelector('.days span');
    let hoursEl = this.elt.querySelector('.hours span');
    let minutesEl = this.elt.querySelector('.minutes span');
    let secondsEl = this.elt.querySelector('.secondes span');
    var countDownDate = this.elt.dataset.date;

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
    if( distance <= 0 || bypass ) {
      clearInterval(this.x);

      if( this.callback ) {
        this.callback.call();
      }
    } else {
      daysEl.innerHTML = days
      hoursEl.innerHTML = (hours < 10 ? '0' : '') + hours;
      minutesEl.innerHTML = (minutes < 10 ? '0' : '') + minutes;
      secondsEl.innerHTML = (seconds < 10 ? '0' : '') + seconds;
    }
  }
}