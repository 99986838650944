import Zoom from '../workshop/zoom'
import Spik from '../workshop/spik'
import Giforama from '../workshop/giforama'
import Video from '../workshop/video' 
import Iframe from '../workshop/iframe'
import Shortcut from '../workshop/shortcut'
import Body from '../workshop/body'
import Quiz from '../workshop/quiz'
import Photo from '../workshop/photo'
import axios from 'axios';
import VimeoPlayer from '../utils/vimeo'
import Media from '../utils/media'
import Plyr from 'plyr';
import { pascalCase } from '../utils/utils'
import Flickity from 'flickity-fade'

const durationShow = 250; // En milliseconde
const popupBase = document.querySelector('.workshop__popup.base')
const Workshops = {
  Zoom : Zoom,
  Video : Video,
  Iframe : Iframe,
  Giforama : Giforama,
  Spik : Spik,
  Shortcut : Shortcut,
  Body : Body,
  Photo : Photo,
  Quiz : Quiz
}

export default class Atelier {
  constructor(opts) {
    let _ = this;
    this.opts = opts;
    
    this.chat = opts.chat ? opts.chat : null;

    this.timerScore = 0;
  }

  init() {

    // Load live if exist
    this.live();

    // Social Wall
    const media = new Media();
    media.socialWall();

    // score box if exist
    this.scoreBox();
    
    // Build trigger workshop
    this.handleWorkshop()

    // Build slider
    const slider = document.querySelectorAll('.slider')
    if( slider) {
      slider.forEach(el => {
        new Flickity(el, {
          lazyLoad: 3,
          adaptiveHeight: true,
          fade: true,
          autoPlay: 5000,
          pauseAutoPlayOnHover: false,
          pageDots: false,
          prevNextButtons: false
        })
      })
    }

    window.addEventListener('update-app', () => {               
      clearTimeout(this.timerScore);
      this.scoreBox();
    })
  }

  load() {
    const _ = this;

    delete _.opts.chat;

    return new Promise((resolve, reject) => {
    
      // Load the tpl
      axios({ 
        method: 'get',
        url: `${url}inc/modules/atelier.php`,
        params : {
          id_event : _.opts.event.id,
          id_module : _.opts.module.id,
        }
      })
      .then((response) => {
        if( response.data ) {
          const el = document.createElement('div');
          el.innerHTML = response.data;
          
          _.panel = el.querySelector('.panel');

          resolve(_.panel);
        }
      })
      .catch((e) => {
        console.log(e);
        reject();
      })

    })
  }
  
  /**
   * Construct player vimeo live
   */
  live() {
    let live = this.panel.querySelector('.live-mainstage');

    if( live ) {
      this.livePlayer = new VimeoPlayer(live.querySelector('.live-mainstage__content'), live, {
        id          : live.dataset.video,
        autoplay    : true,
        adaptive    : true,
        fullscreen  : false,
        loop        : true,
        features: {
          fullscreen : false,
          volume : true
        },
      })

      document.body.addEventListener('mute', () => {
        this.livePlayer.mute()
      })
      document.body.addEventListener('unmute', () => {
        this.livePlayer.unmute()
      })
    }
  }

  scoreBox() {
    const _ = this;
    const scoreBox = document.querySelectorAll('.score-item');

    if( scoreBox ) {
      scoreBox.forEach(box => {
        const val = box.querySelector('.val');
        const progress = box.querySelector('.progress');
        const bar = progress.querySelector('.bar');

        axios({ 
          method: 'get',
          url: `${url}inc/.ajax.php`,
          params : {
            action : 'get-score',
            rules : box.dataset.rules,
            id_event : this.opts.event.id,
          }
        })
        .then(response => {
          if( response.data ) {
            const score = response.data
            const scoreFormat = score.toLocaleString('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            })

            val.innerText = scoreFormat

            // Calcul the new progress bar
            const max = parseFloat(progress.dataset.max)

            bar.style.width = `${score * 100 / max}%`

            this.timerScore = setTimeout(() => {
              _.scoreBox();
            }, 60000)
          }
        })
        .catch(() => {

        })
      })
    }
  }

  playerVideo(elt) {
    Array
      .from(elt)
      .map(p => {
        console.log(p);
        new Plyr(p, {
          controls : ['play-large', 'progress', 'mute', 'fullscreen']
        })
      });
  }

  handleWorkshop() {
    const _ = this;

    let workshopLinks = this.panel.querySelectorAll('.workshop__trigger')

    if( workshopLinks ) {
      workshopLinks.forEach(el => {
        el.addEventListener('click', (e) => {
          e.preventDefault();

          // Pause all video
          const videos = document.querySelectorAll('.media__list video')
          if( videos ) videos.forEach(video => { video.pause() })

          // If target blank
          if( el.dataset.target && el.dataset.target == '_blank') {
            window.open(el.dataset.url, el.dataset.workshopName);
            return false
          }

          // Stop live if exist
          if( _.livePlayer ) {
            _.livePlayer.mute();
          }

          // Workshop type
          let type = el.dataset.workshopType;

          // Create new popup
          let popup = popupBase.cloneNode(true);
          document.body.appendChild(popup)

          axios({
            method: 'get',
            url : `${url}/workshop/${type}.php?id=${el.dataset.workshopId}&id_event=${this.opts.event.id}`
          })
          .then(response => {
            if( response.data ) {
              // Get html response
              let html = document.createElement('div')
              html.innerHTML = response.data;

              // Get container ajax
              let container = html.querySelector('.app');

              if( type != 'body' && type != 'shortcut') {
                popup.classList.add('show');
                document.querySelector('html').classList.add('show-workshop')

                popup.querySelector('.workshop__popup--content').appendChild(container);
              }


              let optsWorkshop = { ...el.dataset}

              // Init the workshop
              let workshop = new Workshops[pascalCase(type)](container, Object.assign(_.opts, optsWorkshop), _.chat)

              // Init close button
              let close = popup.querySelectorAll('.popup-close');
              if( close ) {
                close.forEach(el => {
                  el.addEventListener('click', closeEvent)
                })

                // Init the close logo button
                const logo = document.querySelector('.main-logo');

                function closeEvent(e) {
                  e.preventDefault();

                  // unmute live if exist
                  if( _.livePlayer ) {
                    _.livePlayer.unmute();
                  }

                  // Play all video
                  const videos = document.querySelectorAll('.media__list video')
                  if( videos ) videos.forEach(video => { video.play() })

                  workshop.destroy();
                  popup.classList.remove('show');
                  document.querySelector('html').classList.remove('show-workshop')

                  setTimeout(() => {
                    popup.remove();
                  }, durationShow)

                  if( logo ) {
                    logo.removeEventListener('click', closeEvent);
                  }
                }

                if( logo ) {
                  logo.addEventListener('click', closeEvent);
                }

                window.addEventListener('destroy.workshop', (e) => {
                  close[0].dispatchEvent(new Event('click'));
                })
              }
            }
          })
        })
      })
    }
  }
}