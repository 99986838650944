import Axios from "axios";

export default class Iframe {
  constructor(elt, opts, chat = null) {

    this.opts = opts;

    if( opts.url ) {
      let iframe = elt.querySelector('iframe');
      iframe.src = `${opts.url}?email=${opts.user.email}&atelier=${opts.workshopId}`
    }

  }

  destroy() {
    const urlApi = 'https://timeline.therope.fr/api/scores';

    if( this.opts.url.indexOf('therope') !== false ) {

      // On récupère le score de l'utilisateur
      Axios({
        url : urlApi,
        params : {
          email : this.opts.user.email,
          atelier : this.opts.workshopId
        }
      })
      .then(response => {
        const results = typeof response.data == 'string' ? JSON.parse(response.data) : response.data;

        if( results ) {

          // On sauvegarde les resultats dans notre base de donnée
          let form = new FormData();
          form.append('results', JSON.stringify(results));
          form.append('id_event', this.opts.event.id)

          Axios({
            method : 'post',
            url : `${url}/inc/.ajax.php?action=post-score`,
            data : form
          })
          .then(response => {
            window.dispatchEvent(new Event('update-app'))
          })
        }
      })
    }
  }
}