import gsap from 'gsap';
import Module from './panels/module'
import Live from './panels/live'
import Login from './panels/login'
import Plyr from 'plyr';
import Blazy from 'blazy'
import axios from 'axios'
import Sidebar from './utils/sidebar';
import {smoothScroll} from './utils/utils';

// import Zoom from './panels/workshop/zoom';
// import Giforama from './workshop/giforama';

const HTML = document.querySelector('html');

class App {

	constructor(page) {
		console.log(`${this.constructor.name}:constructor`)
		this.current = 0;
		this.panel = null;

		// Prevent scrollTop on click on a[href="#"] links
		let linksEmpty = document.querySelectorAll('a[href="#"]');
		if( linksEmpty ) linksEmpty.forEach((el) => { el.addEventListener('click', function(e) { e.preventDefault(); }) });

		let linksAnchor = document.querySelectorAll('a[href*="#"]:not([href="#"])');
		if( linksAnchor ) linksAnchor.forEach((el) => { el.addEventListener('click', smoothScroll) });

		// Check if mac for better font aliasing
		if (navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC') HTML.classList.add('is-mac')

		// Video player
		let players = document.querySelectorAll('.player-custom');
		if( players ) {
			this.customPlayer(players);
		}

		// Programs sidebar
		let programs = document.querySelectorAll('.programme__items');
		if( programs ) {
			this.buildProgramDetail(programs);
		}

		// Blazy
		this.blazy = new Blazy({
			breakpoints: [{
				width: 420,
				src: 'data-src-small' 
			 }],
			selector : '.js-lazy',
			success : function(elt) {
				if( elt.closest('figure') )
					elt.closest('figure').classList.add('img-is-loaded')
			}
		});

		// Popup 
		new Sidebar('', () => {
			const links = document.querySelectorAll('.programme__item--link');
			if( links ) links.forEach((link) => link.remove()); 
		});

		this.init(page);

		return this
	}

	/**
	 * Init the page
	 * @return {object} The current instance
	 */
	init(page) {
		console.log(`${this.constructor.name} is init`)

		// this.login();

		if( page == 'live') {
			this.panel = new Live({
				event : event,
				user : (typeof user !== 'undefined' ? user : null),
				idLive : idLive
			});
		} 
		else if( page == 'module') {
			this.panel = new Module({
				event : event,
				user : (typeof user !== 'undefined' ? user : null)
			});
		} else if( page == 'login') {
			this.panel = new Login();
		}

		if( gtag ) {
			gtag('event', 'screen_view', {
				'app_name': event.slug,
				'screen_name' : page
			});

			gtag('event', `event_${event.slug}`, {
				'event_category' : `page_view_${page}`
			})

			gtag('event', 'page_view', {
				'page_title' : document.title,
				'page_path': event.slug + location.pathname
			});
		}
 
		return this;
	}

	/**
	 * Move to panel
	 * @param {int} index : index of panel
	 */
	goto(index) {
		let panel = document.querySelectorAll('.panel')
		gsap.to(panel[this.current], { duration : 0.3, x : '-100%'})
		gsap.fromTo(panel[index], {autoAlpha : 1, x : '100%'}, { duration : 0.3, x : '0%'});
	}

	/**
	 * 
	 * @param {HTML Collection} elts list of html player
	 */
	customPlayer(elts) {
		elts.forEach(el => {

			const player = new Plyr(el, {
				controls : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
				// controls : ['play-large'],
			});

			player.on('ready', (e) => {

				// Build title on video
				let playerInfo = document.createElement('div')
				playerInfo.classList.add('player-info');
				player.elements.container.append(playerInfo);
	
				if( el.dataset.title != '') {
					playerInfo.innerHTML += `<h1>${el.dataset.title}</h1>`
				}
				if( el.dataset.subtitle != '') {
					playerInfo.innerHTML += `<h3>${el.dataset.subtitle}</h3>`
				}
			})
			player.on('play', (e) => {
				player.elements.container.classList.add('show-controls')
			})
			player.on('pause', (e) => {
				player.elements.container.classList.remove('show-controls')
			})

		})
	}

	/**
	 * 
	 * @param {HTML Collection} elts list of html programs iitems
	 */
	buildProgramDetail(elts) {
		function closeOverlay(e) {
			if( !e.target.classList.contains('sidebar') && !e.target.closest('.sidebar') ) {
				e.stopPropagation();
				e.preventDefault();
				const close = document.querySelectorAll('.sidebar--close');
				if( close ) {
					close[0].dispatchEvent(new Event('click'));
				}
				
			}
		}
		elts.forEach(el => {
			const close = el.querySelectorAll('.sidebar--close');
			const links = el.querySelectorAll('.programme__item--link');

			if( close ) {
				close.forEach(c => {
					c.addEventListener('click', e => {
						e.preventDefault();

						const parent = c.closest('.sidebar');

						parent.classList.remove('show');
						HTML.classList.remove('show-programme-sidebar');
						HTML.removeEventListener('click', closeOverlay);
					})
				})
			}

			if( links ) {
				links.forEach(link => {
					link.addEventListener('click', e => {
						e.preventDefault();
						e.stopPropagation();
						const parent = link.closest('.programme__item');

						parent.nextElementSibling.classList.toggle('show');
						HTML.classList.toggle('show-programme-sidebar');
						
						HTML.removeEventListener('click', closeOverlay);
						if( HTML.classList.contains('show-programme-sidebar') ) {
							HTML.addEventListener('click', closeOverlay);
						}
					})
				})
			}
		})
	}
}

window.App = App;