import Zoom from '../workshop/zoom'
import Spik from '../workshop/spik'
import Giforama from '../workshop/giforama'
import Video from '../workshop/video'
import Iframe from '../workshop/iframe'
import axios from 'axios';
import VimeoPlayer from '../utils/vimeo'
import Plyr from 'plyr';
import { pascalCase } from '../utils/utils'

const durationShow = 250; // En milliseconde
const popupBase = document.querySelector('.workshop__popup.base')
const Workshops = {
  Zoom : Zoom,
  Video : Video,
  Iframe : Iframe,
  Giforama : Giforama,
  Spik : Spik
}

export default class Mainstage {
  constructor(opts, chat) {
    let _ = this;
    this.opts = opts;

    // Load live if exist
    _.live();

    // Move chat
    // _.moveChat();

    // Social Wall
    _.socialWall();

    let workshopLinks = document.querySelectorAll('.workshop__trigger')

    if( workshopLinks ) {
      workshopLinks.forEach(el => {
        el.addEventListener('click', (e) => {
          e.preventDefault();

          // If target blank
          if( el.dataset.target && el.dataset.target == '_blank') {
            window.open(el.dataset.url, el.dataset.workshopName);
            return false
          }

          // Stop live if exist
          if( _.livePlayer ) {
            _.livePlayer.mute();
          }

          // Workshop type
          let type = el.dataset.workshopType;

          // Create new popup
          let popup = popupBase.cloneNode(true);
          document.body.appendChild(popup)

          axios({
            method: 'get',
            url : `${url}/workshop/${type}.php?id=${el.dataset.workshopId}`
          })
          .then(response => {
            if( response.data ) {
              // Get html response
              let html = document.createElement('div')
              html.innerHTML = response.data;

              // Get container ajax
              let container = html.querySelector('.app');
              popup.classList.add('show');
              document.querySelector('html').classList.add('show-workshop')

              popup.querySelector('.workshop__popup--content').appendChild(container);

              let optsWorkshop = { ...el.dataset}

              // Init the workshop
              let workshop = new Workshops[pascalCase(type)](container, Object.assign(_.opts, optsWorkshop), chat)

              // Init close button
              let close = popup.querySelectorAll('.popup-close');
              if( close ) {
                close.forEach(el => {
                  el.addEventListener('click', (e) => {
                    e.preventDefault();

                    // unmute live if exist
                    if( _.livePlayer ) {
                      _.livePlayer.unmute();
                    }
    
                    workshop.destroy();
                    popup.classList.remove('show');
                    document.querySelector('html').classList.remove('show-workshop')

                    setTimeout(() => {
                      popup.remove();
                    }, durationShow)
                  })
                })
              }
            }
          })
        })
      })
    }
  }

  moveChat() {
    let live = document.querySelector('.live-mainstage');
    let chatMain = document.querySelector('.chat__main');
    let chat = document.querySelector('.chat');
    let newContainer = document.querySelector('#chat-mainstage');
    newContainer.append(chat);
    chatMain.remove();

    if( live ) {
      window.addEventListener('resize.chat', (e) => {
        let pos = live.getBoundingClientRect();
        let height = live.offsetHeight;
        let newHeight = window.innerHeight - (pos.top + height);
        newContainer.style.height = `${newHeight * 0.95}px`;
      })
  
      window.dispatchEvent(new Event('resize.chat'));
    }
  }

  /**
   * Construct player vimeo live
   */
  live() {
    let live = document.querySelector('.live-mainstage');

    if( live ) {
      this.livePlayer = new VimeoPlayer(live.querySelector('.live-mainstage__content'), live, {
        id          : live.dataset.video,
        autoplay    : true,
        muted       : true,
        fullscreen  : true
      })
    }
  }

  /**
   * All  medias
   */
  socialWall() {
    let medias = document.querySelectorAll('.media__list')
    
    if( medias ) {
      medias.forEach(media => {
        this.autoloadMedia(media, 6, (media.dataset.media == 'spik' ? 'gif' : 'video'));
      })
    }
  }

  /**
   * Display new media
   * 
   * @param {HTML element} elt the element which inser the new media
   */
  autoloadMedia(elt, limit = 0, exclude_type = 'video') {
    let _ = this;
    let selector = '.media';
    let baseMedia = elt.querySelector(`${selector}.base`);

    axios({
      method: 'get',
      url: `${url}inc/.ajax.php?action=get-medias&id_event=${event.id}&limit=${limit}&exclude_type=${exclude_type}`
    })
    .then(function (response) {
      if( response.data) {
        response.data.forEach( media => {

          let inSocialWall = elt.querySelector(`#media-${media['id']}`);
          if( inSocialWall ) {
            return false;
          }

          // Build the new media
          let newMedia = baseMedia.cloneNode(true);
          newMedia.classList.remove('base');
          newMedia.setAttribute('id', `media-${media['id']}`);

          // If video 
          if( newMedia.querySelector('video') ) {
            let video = newMedia.querySelector('video');
            let url = media['url'][0];
            let poster = url.replace('upload/', 'upload/w_700,so_5.8,du_1/');
            var source = document.createElement('source');
            source.src = url;
            source.type = 'type/mp4'
            video.poster = poster.replace('.mp4', '.png');
            video.src = url

            // _.playerVideo([video]);
          } 
          else 
          {
            let img = newMedia.querySelector('img')
            img.setAttribute('data-src', media['url'][0]);
            img.setAttribute('src', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
            img.classList.remove('b-loaded');
          }

          elt.prepend(newMedia);
          let medias = elt.querySelectorAll(`${selector}:not(.base)`);
          window.MainApp.blazy.revalidate();

          if( limit > 0 && medias.length > limit ) {
            for(var i = medias.length-1; i > limit-1; i-- ) {
              medias[i].remove();
            }
          }
        })
      }
        
      setTimeout(() => {
        _.autoloadMedia(elt, limit, exclude_type);
      }, 30000)
    })
    .catch((error) => {        
      setTimeout(() => {
        _.autoloadMedia(elt, limit, exclude_type);
      }, 30000)
    });
  }

  playerVideo(elt) {
    Array
      .from(elt)
      .map(p => {
        new Plyr(p, {
          controls : ['play-large', 'progress', 'mute', 'fullscreen']
        })
      });

  }

  infinitescrollMedia(elt) {
    // elt.addEventListener('.')
  }
}