import * as PIXI from 'pixi.js'
import axios from 'axios';
import gsap from 'gsap'
import {alert} from '../utils/utils'
import { SplitText } from "gsap/SplitText.js";
// const Flickity = require('flickity');
// const {TimelineLite, TweenLite } = require('gsap')
// import SplitText from "./vendors/gsap-bonus/SplitText";
    
// On charge la base de donnée
// const optsGnl = settings.general;
// const _.opts.config = settings.settings;

const HTML = document.getElementsByTagName('html')[0]

const log = {
	info : function(msg) {
		return;
		console.info(msg);
	},
	error : function(msg) {
		console.error(msg)
		HTML.classList.add('show-error')
	}
}
function wait(delayInMS) {
  return new Promise(resolve => setTimeout(resolve, delayInMS));
}


export default class Spik {

  constructor(elt, opts) {
    let _ = this
    
    this.opts = opts

		this.settings = {
			selector : '.spik .panel',
			current : -1,
			liveview : {
				width : 800,
				height : 800
			},
			video : {
				width : 1280,
				height : 720
			},
			steps : document.querySelectorAll('.spik .panel').length
		} 

		this.onRecord = false
    this.timerPause = 0;
    
    // On charge les settings 
    if( this.opts.settings ) {
      axios({
        method : 'get',
        url : url + this.opts.settings
      })
        .then((response) => {
          if( response.data ) {
            _.opts.config = response.data.options;

            _.init();
          } else {
            alert('Probème de configuration');
          }
        })
    }
  }
  
  init() { 

		this.initItw();
		
    // ---------------------
    // Create intro panel
		// ---------------------
		this.panelIntro()
		
    // ---------------------
    // Create liveview panel
		// ---------------------
		this.panelLiveview()
		
    // ---------------------
    // Create questions panel
		// ---------------------
		this.panelQuestions()
		
    // ---------------------
    // Create preview panel
		// ---------------------
		this.panelPreview()
		
    // ---------------------
    // Create email panel
		// ---------------------
		this.panelFinish()
		
    // ---------------------
    // Go on first panel
    // ---------------------
		this.goto(0);
  } 

	loadVideos() {
		let _ = this;
		this.questions = _.opts.config.questions;

    // ---------------------
    // Load video
    // ---------------------
    this.questions.forEach((q, i) => {
    	if(q['style']['bg-file'].length > 0) {
	    	let v = document.createElement('video')
	    	v.preload = 'auto';
	    	v.src = q['style']['bg-file'][0]['url'];
	    	v.load();

	    	_.questions[i]['bg-video'] = v;
    	}
    })
	}

	initItw() {
		let _ = this;

    this.loadVideos();

		_.itw = {
			id : Date.now(),
			questions : [],
			infos : [],
			intro : _.opts.config.intro[0]['url'],
			outro : _.opts.config.outro[0]['url'],
			song : _.opts.config.loopSong[0]['url'],
			duration_intro : 0,
			duration_outro : 0,
			start_itw : 0,
			records : [],
			email: user.email,
      save : 0,
      event: event.id,
			type : 'video',
		}

		// Clear form
		let inputs = document.querySelectorAll('input');
		if( inputs ) {
			inputs.forEach((el) => {
				el.value = '';
			})
		}
	}
	
	createLiveview(elt) {
		let _ = this
		// elt.innerHTML = '';

		let link = document.querySelectorAll('a[disabled]');

		let wCanvas = this.settings.liveview.width
		let hCanvas = this.settings.liveview.height 
		let wCamera = this.settings.video.width
		let hCamera = this.settings.video.height 
		
		_.cLive = new PIXI.Application({ width: wCanvas, height: hCanvas });
		// elt.appendChild(_.cLive.view);
		
		let videoSprite = new PIXI.Sprite();
		_.cLive.stage.addChild(videoSprite);

		// let camera = document.createElement('video')
		let camera = document.querySelector('.liveview__video')
		let introVideo = document.querySelector('.camera--video video')
		let questionVideo = document.querySelector('.question__liveview video')

		navigator.mediaDevices.getUserMedia({
			audio : true,
			video: {
		    width: { ideal: wCamera },
		    height: { ideal: hCamera }
			}
		}).then(function(stream) {
			if( link ) {
				link.forEach((el) => {
					el.removeAttribute('disabled')
				})

				document.querySelector('.notice-required').remove();
			}

			_.stream = stream
			camera.srcObject = _.stream
			introVideo.srcObject = _.stream
			questionVideo.srcObject = _.stream

			camera.captureStream = camera.captureStream || camera.mozCaptureStream;
			let {width, height} = stream.getTracks()[0].getSettings();

		  camera.oncanplay = function(e) {
		    // camera.play();
		  };

		  if( width != null && height != null ) {
				wCamera = width;
				hCamera = height;
				_.settings.video.width = wCamera;
				_.settings.video.height = hCamera;
		  }
    
			let texture = PIXI.Texture.from(camera);

			let newW = wCamera > hCamera ? wCamera * hCanvas / hCamera : wCanvas;
			let newH = wCamera > hCamera ? hCanvas : hCamera * wCanvas / wCamera;

			videoSprite.texture = texture
			videoSprite.anchor.set(0.5);
			videoSprite.scale.x = -1
			videoSprite.x = wCanvas / 2
			videoSprite.y = hCanvas / 2;
			videoSprite.width = newW;
			videoSprite.height = newH;
		});
	}

	goto(index, callback = null) {
		let _ = this
		let elts = document.querySelectorAll(this.settings.selector);
		let currentElt = elts[this.settings.current];
		let nextElt = elts[index];

		let dir = index > this.settings.current ? 1 : -1;

		if( currentElt != undefined ) {
			gsap.to(
        currentElt, {
          duration: 0.5,
          x : (-100 * dir) + '%', 
          ease : 'circ.inOut' 
        }
      )
    }
		gsap.fromTo(
      nextElt, 
      { 
        autoAlpha : 1, 
        x : (100 * dir) + '%'
      }, 
      {
        duration: 0.5, 
        x : '0%', 
        delay : 0.1, 
        ease : 'circ.inOut', 
        onComplete : function() {
          if( typeof callback == 'function') {
            callback.call();
          }

          // Finish
          if( index == _.settings.steps - 1 ) {
            _.finish()
          }
        } 
      }
    )

    // Pause the video prev if exist
    if( currentElt != null ){
      let videoPause = currentElt.querySelector('video');
      if( videoPause ) videoPause.pause();
    } 

    // Play the video next if exist
    let videoPlay = nextElt.querySelector('video');
    if( videoPlay ) videoPlay.play();

		this.settings.current = index;
	}

	buildTlItw(elt) {
		let _ = this;
		let recordFile = '';
		let progress = elt.querySelector('.question__progress')
		let progressRead = elt.querySelector('.question__read')
		let liveview = elt.querySelector('.question__liveview')
		let labelRec = elt.querySelector('.rec')
		let labelPause = elt.querySelector('.pause')
		
    // ---------------------
    // Prepare question
		// ---------------------
		if( _.opts.config.activeRandom ) {

			// Sort all questions
			let questionsGroup = [];

			this.questions.forEach((q, i) => {
				if( questionsGroup[q.random] == undefined ) questionsGroup[q.random] = [];

				questionsGroup[q.random].push(q);
			})

			// Random questions
			let questions = [];
			questionsGroup.forEach((q, i) => {
				q.sort( () => Math.random() - 0.5);
				questions.push(q[0]);
			})

			this.questions = questions
		}

		let questions = _.questions;

		// Construct tl
		this.tlItw = gsap.timeline({ paused : true, onStart : function() {
			recordFile = _.itw.event + '-{nb}-' +  _.itw.id
		}})

		// Build panel question
		questions.forEach((question, i) => {
			let step = document.createElement('div');
			let recordedBlob = null;
			step.classList.add('question__item');
			step.innerHTML = question.content;

			new SplitText(step.querySelector('p'), { type:"chars" })
			elt.appendChild(step);

			let progressTxt = document.createElement('div');
			progressTxt.classList.add('progress-txt');
			progressTxt.innerHTML = question.content
			step.appendChild(progressTxt);
			let split = new SplitText(progressTxt.querySelector('p'), { type:"chars" })

			let staggerDelay = question.times.timeRead / split.chars.length
			let qVideo = question['bg-video'];

			_.tlItw
					.to(HTML, .2, { backgroundColor : '#FF414D' }, `start-${i}`)
					.set(progress, { autoAlpha : 0, y : '-100%' }, `start-${i}`)
					.to(liveview, 0.3,{ autoAlpha : 0 }, `start-${i}`)
					.fromTo(step, 0.4, { autoAlpha : 0, x : '100vw', y : '-50%' }, { autoAlpha : 1, x : '-50%', y: '-50%', ease : 'power1.inOut' }, `start-${i}`)
					.staggerFromTo(progressTxt.querySelectorAll('div'), 0.1, { autoAlpha : 0}, { autoAlpha : 1}, staggerDelay, `start-${i}+=.4`)
					.fromTo(progressRead.querySelector('i'), question.times.timeRead, { autoAlpha : 1, width : '0%' }, { width : '100%', ease : 'linear.none', onStart : () => {
						//On lit la vidéo en fond si il y a du son
            if( qVideo) qVideo.play();
					}, onComplete : () => {
						//On stop la vidéo et on remet à zero	
						if( qVideo) {
							qVideo.pause();
							qVideo.currentTime = 0;
						}
					}}, `start-${i}+=.4`)
					.to(step, 0.4, { autoAlpha : 0}, `endread-${i}`)
					.to(progressRead, 0.4, { autoAlpha : 0 }, `endread-${i}`)
					.to(liveview, .3, {autoAlpha : 1}, `startrecord-${i}`)
					.to(HTML, .2, { backgroundColor : '#022c42' }, `startrecord-${i}`)
					.fromTo(labelRec, .4, { autoAlpha : 0, x : '100%' }, { autoAlpha : 1, x : '0%', ease : 'circ.inOut' }, `startrecord-${i}`)
					.to(progress, 0.3, { autoAlpha : 1, y: '0%', ease : 'power1.inOut' }, `startrecord-${i}`)
					.fromTo(progress.querySelector('i'), question.times.timeAnswer + .2, { autoAlpha : 1, width : '0%' }, { width : '100%', ease : 'linear.none', onStart : function() {
						
						log.info('Go question : ' + (i+1) );

						// start recording
						_.startRecording(_.stream, question.times.timeAnswer * 1000)
							.then (recordedChunks => {
								_.itw.questions[i] = question;
								_.itw.questions[i].silences = [];

						    recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });
						    let filename = recordFile.replace('{nb}', i) + '.mp4'

						    _.itw.records.push({ 
						    	'filename' : filename,
						    	'blob' : recordedBlob
						    });
						    
						    log.info("Successfully recorded " + recordedBlob.size + " bytes of " +
						        recordedBlob.type + " media.");
						  })
							.catch((err) => {
								alert('-- ERROR : Impossible start video record');
								alert(err);
							}); 
					}, onComplete : function() {
						// _.tlItw.pause();
						gsap.to(progress, .3, { autoAlpha : 0 })
						// Next step 
						if( i >= questions.length - 1 ) {
							_.buildITW()
							_.goto(_.settings.current + 1, () => {
								gsap.set(liveview, { autoAlpha : 0});
							});
						}

					}}/*, '+=0.1'*/)
					.set(progress, { autoAlpha : 0 }, `end-${i}`)
					.to(labelRec, 0.4, { autoAlpha : 0}, `end-${i}`)
					// .to(progress, .3, { autoAlpha : 0 }, `end-${i}`)
					.add(() => { labelRec.classList.remove('saving') }, '+=.1')
					.to(step, 0.4, { autoAlpha : 0}, `end-${i}`)
					.set(progress.querySelector('i'), { scaleX : 1, width : '0%'})
		})
	}
	
	buildITW() {
		let _ = this;
		let panel = document.querySelector('.panel.preview');
		let progress = panel.querySelector('.preview__progress')
		let loader = progress.querySelector('.video-loader')
		let container = panel.querySelector('.preview__container')
		let txtProgress = panel.querySelector('.preview__progress--txt label');

		gsap.to(progress, 0.3, { autoAlpha : 1})
		loader.play();

		log.info('Build interview');

	  _.itw.recordSize = {
	  	width : _.settings.video.width,
	  	height : _.settings.video.height
	  }

	  txtProgress.innerText = 'Envoi des vidéos'

	  /*************************
	  /*** On enregistre les records
	  /*************************/
		let formData = new FormData();

		let filenames = [];
		for(var i = 0; i < _.itw.records.length; i++) {
			filenames.push(_.itw.records[i].filename);
			
			formData.append('video_' + i, _.itw.records[i].blob);
		}
		formData.append('filenames', JSON.stringify(filenames))

    axios({
      method : 'post',
      url : `${url}inc/.ajax.php?action=save-video`,
      data : formData
    })
    .then((response) => {
      if( response.data && response.data.statut ) {
        for(var i = 0; i < response.data.paths.length; i++) {
          _.itw.questions[i].filename = response.data.paths[i];
        }

        /*************************
        /*** On génère la vidéo
        /*************************/

        let formData = new FormData();
        formData.append('itw', JSON.stringify(_.itw))

        axios({
          method: 'post',
          url : `${url}inc/.ajax.php?action=build-itw`,
          data : formData
        })
        .then((response) => {
          gsap.to(progress, 0.3, { autoAlpha : 0})
          loader.pause();
          loader.currentTime = 0;

          if( response.data && response.data.statut ) {

            log.info('Build interview finished');
            _.itw.final = response.data.final
            _.itw.duration_intro = parseFloat(response.data.intro_duration) + parseFloat(_.itw.questions[0].times.timeRead);


            // Add video in preview container
            let preview = document.querySelectorAll('.finish__video')
            if( preview ) {
              preview.forEach( p => {
                p.src = _.itw.final
                p.loop = true
                p.play()
              })
            }

            setTimeout(() => {
              _.goto(_.settings.current+1)
            }, 100)
          }  else {
            alert('Erreur lors de la création de la vidéo')
          }
        })

        txtProgress.innerText = 'création vidéo en cours'
      } else {
        alert('Erreur lors de l\'enregistrement du record');
      }
    })
		
	}

	startRecording(stream, lengthInMS) {
	  let recorder = new MediaRecorder(stream);
	  let data = [];
	 
	  recorder.ondataavailable = event => data.push(event.data);
	  recorder.start();
	  log.info(recorder.state + " for " + (lengthInMS/1000) + " seconds...");
	 
	  let stopped = new Promise((resolve, reject) => {
	    recorder.onstop = resolve;
	    recorder.onerror = event => reject(event.name);
	  });

	  let recorded = wait(lengthInMS).then(
	    () => recorder.state == "recording" && recorder.stop()
	  );
	 
	  return Promise.all([
	    stopped,
	    recorded
	  ])
	  .then(() => data);
	}


	finish() {
    let _ = this;

		let panel = document.querySelector('.panel.finish');
		let dl = panel.querySelector('.dl-btn');
		let content = panel.querySelector('.content');
		let progress = panel.querySelector('.progress');

		let hContent = 0;
		for (var i = 0; i < content.children.length; i++) {
			hContent += content.children[i].offsetHeight
		}
		
		_.panelQuestions();
	}

	/** ================================================= */
	/** ================================================= */
	/** ================================================= */
	/** ====== PANELS ======= */
	/** ================================================= */
	/** ================================================= */
	/** ================================================= */
	panelIntro() {
		let _ = this;
		let panel = document.querySelector('.panel.intro');
		let liveviewPanel = document.querySelector('.panel.liveview');
		let slider = panel.querySelector('.slider')
		let goto = panel.querySelector('.trigger-start')

		goto.addEventListener('click', (e) => {
			e.preventDefault();
			_.goto(_.settings.current+1, () => {
				liveviewPanel.dispatchEvent(new Event('click'))
			})
		})
	}

	panelLiveview() {
		let _ = this;
		let liveviewPanel = document.querySelector('.panel.liveview');
		let countdown = liveviewPanel.querySelector('.liveview__countdown');
		let step = 0;
		let colors = ['#ffb2b7', '#40c0f1', '#ffdb57'];

		_.createLiveview(liveviewPanel.querySelector('.liveview__canvas'))
		
		// Construct live view tl
		_.tlStart = gsap.timeline({ paused : true, onComplete : function() {
			_.goto(_.settings.current+1, function() {
				gsap.set(liveviewPanel, { clearProps : 'all' });
				step = 0;

				_.tlItw.seek(0).play();

				// Clear all props
				_.tlStart.pause().seek(0)
			});
		 }})

		 _.tlStart
		 	// .to(liveviewPanel.querySelector('.txt:not(.txt-pause)'), 0.4, { autoAlpha : 0, y : '10%' }, 'start')
			.fromTo(
        countdown.querySelectorAll('span'), 
        { 
          y : '-60%', 
          autoAlpha : 0
        }, 
        { 
          y : '-50%', 
          autoAlpha : 1, 
          duration: 0.45,
          stagger: 1,
          onStagger : () => {
            gsap.to(liveviewPanel, { duration:0.45, background : colors[step] });
            step++;
          }
        }, 
        'countdown'
      )
			.staggerTo(countdown.querySelectorAll('span'), .45, { y : '-40%', autoAlpha : 0 }, 1, 'countdown+=.45')
			.to(liveviewPanel.querySelector('.liveview__canvas'), 0.4, { autoAlpha : 0 })

		// Start the itw
		liveviewPanel.addEventListener('click', (e) => {
			e.preventDefault();
			let cTime = _.tlStart.time();
			clearTimeout(_.timerPause);
			if(cTime == 0 || cTime == _.tlStart.duration()) {
				_.tlStart.seek(0).play();
				_.itw.id = Date.now();

				// Init default infos
				_.initItw()
			}
		})
	} 

	panelQuestions() {
		let _ = this;
		let questionPanel = document.querySelector('.panel.question');

		// Clean panel
		var items = questionPanel.querySelectorAll('.question__item');
		items.forEach((el) => {
			el.parentNode.removeChild(el);
		})

		// Build itw cursus
		this.buildTlItw(questionPanel);
	}	

	panelPreview() {
		let _ = this;
		let panel = document.querySelector('.panel.preview');

		// let btnRestart = panel.querySelector('.restart')
		// let btnContinue = panel.querySelector('.continue')
		// let container = panel.querySelector('.preview__container')

		// // Restart 
		// btnRestart.addEventListener('click', (e) => {
		// 	e.preventDefault();

		// 	// Stop video
		// 	panel.querySelector('.preview__video video').pause();

		// 	log.info('Start liveview');

		// 	// Go to first panel
		// 	_.goto(1, reinitPanel);
		// })

		// // Continue 
		// btnContinue.addEventListener('click', (e) => {
		// 	e.preventDefault();

		// 	// Stop video
		// 	panel.querySelector('.preview__video video').pause();

		// 	// Go to first panel
		// 	_.goto(_.settings.current + 1, reinitPanel);
		// })

		function reinitPanel() {
			gsap.set(container, { clearProps:"all" });
		}
	}

	panelFinish() {
    let _ = this;

		let panel = document.querySelector('.panel.finish');
		let content = panel.querySelector('.content');
		let progress = panel.querySelector('.progress');
		let preview = panel.querySelector('.finish__video');

		panel.querySelector('.trigger-restart').addEventListener('click', (e) => {
			e.preventDefault();

			preview.pause();

			// Go to first panel
			_.goto(0, () => {
				gsap.set([progress, content],  { clearProps : 'all' });
			});
		})
		panel.querySelector('.trigger-validate').addEventListener('click', (e) => {
			e.preventDefault();

			preview.pause();
			gsap.set(progress, { opacity : 1});

			let form = new FormData();
	
			for (let [key, value] of Object.entries(_.itw)) {
				form.append(key, value);
			}
    
			axios({
				method : 'post',
				url: `${url}/inc/.ajax.php?action=save-media`,
				data : form
			}).then((response) => {
				if( response.data ) {
					if( !response.data.statut) {
						alert(response.data.msg);
					} else {

						// Go to first panel
						_.goto(0, () => {
							gsap.set([progress, content],  { clearProps : 'all' });
						});
					}
				}
			})
		})
	}		

	destroy() {

	}	
}