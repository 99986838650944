import Axios from 'axios';
import { gsap } from 'gsap'
import {alert} from '../utils/utils';

const POINT = 100;
const HTML = document.querySelector('html');

export default class Quiz {
  constructor(elt, opts, chat = null) {

    this.opts = opts;
    this.elt = elt;

    this.opts.labels = {
      current : 'Je valide',
      next : 'Suivant',
      finish : 'Retour'
    }

    this.current = -1;

    this.steps = elt.querySelectorAll('.quiz__step');
    this.nbStep = this.steps.length - 1

    this.results = [];

    this.goto(0)
    this.handle();
  }

  handle() {
    const triggers = this.elt.querySelectorAll('[data-next]')
    if( triggers ) {
      triggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
          e.preventDefault();
          
          const next = this.current + 1

          if( next > this.nbStep) return false;

          const step = this.steps[this.current];

          // Get the goods choices
          const goodsChoices = step.querySelectorAll('input[data-good="1"]')
          let goods = []
          if( goodsChoices ) {
            goodsChoices.forEach(good => {goods.push(good.value)})
          }

          // Check if a choice is selected
          const choices = step.querySelectorAll('input[type="radio"]:checked, input[type="checkbox"]:checked')
          if( choices.length <= 0 ) 
          {
            alert('Veuillez choisir au moins 1 option.');
            return false;
          }

          let values = [];
          choices.forEach(choice => {values.push(choice.value)})

          // Save choice 
          if( !step.save ) {

            this.results.push({
              question : step.dataset.question,
              choices : values,
              goods : goods
            })

            Axios({
              method: 'GET',
              url: `${url}inc/.ajax.php`,
              params: {
                action : 'save-quiz-choice',
                id_user : this.opts.user.id,
                id_event : this.opts.event.id,
                id_workshop : this.opts.workshopId,
                id_question : step.dataset.question,
                choices : values.join(',')
              }
            })
            .then(response => {
              if( response.data) {
                if( response.data.statut )
                {
                  step.save = true;

                  // A la fin du quiz
                  // on sauvegarde le résultat
                  if(next >= this.nbStep) {
                    const nbQuestions = this.results.length
                    let nbGoods = 0;

                    this.results.forEach(result => {

                      result.choices.forEach(choice => {
                        if( result.goods.includes(choice) ) {
                          nbGoods++;
                          return;
                        }
                      })
                    })

                    // Save the score 
                    const data = [{
                      'email' : this.opts.user.email,
                      'codeAtelier': this.opts.workshopId, // Id workshop   
                      'timeline': this.opts.workshopId, // Id quiz = id workshop
                      'id' : '-1', // Id result
                      'score' : nbGoods * POINT
                    }]
                    let form = new FormData();
                    form.append('results', JSON.stringify(data));
                    form.append('id_event', this.opts.event.id)

                    Axios({
                      method : 'post',
                      url : `${url}/inc/.ajax.php?action=post-score`,
                      data : form
                    })
                    .then(response => {
                      window.dispatchEvent(new Event('update-app'))
                    })
                  }
                  this.goto(next);
                }
                else {
                  alert(response.data.msg)
                }
              } else {
                alert('Veuillez réessayer une fois votre navigateur raffraichi.')
              }
            })
            .catch((error) => {
              alert(error);
            })
          } else {
            this.goto(next);
          }
        })
      })
    }
  }

  goto(next) {
    let delay = 0;
    const move = 50;

    if( this.current >= 0 ) {
      const step = this.steps[this.current];

      if(step.dataset.showResult && parseInt(step.dataset.showResult) == 1 ) {
        // Show the result
        this.showResult();

        return false;
      } else {

        // gsap.to(step, { duration : 0.8, y : move*-1, x: move*-1, ease : "power2.in" })
        gsap.to(step, { duration : 0.8, marginLeft : move*-1, marginTop: move*-1, ease : "power2.in" })
        gsap.to(step, { duration : 0.3, autoAlpha : 0, delay: 0.3 })
        delay = 0.5;
      }
    }

    gsap.to(this.steps[next], { duration : 0.3, autoAlpha : 1, delay : delay})
    gsap.fromTo(this.steps[next], { marginLeft : move, marginTop: move }, { duration : 0.8, marginLeft: 0, marginTop: 0, delay : delay, ease : "power2.out" })
    this.current = next;

    // On change l'intitulé du bouton
    const triggers = this.elt.querySelectorAll('[data-next]')
    if( triggers ) {
      triggers.forEach(trigger => {

        if( next == this.nbStep) {
          trigger.innerHTML = `<span>${this.opts.labels.finish}</span>`
          trigger.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();

            document.querySelector('.main-logo').dispatchEvent(new Event('click'));

            return false;
          })
        } else {
          trigger.innerHTML = `<span>${this.opts.labels.current}</span>`
        }
      });
    }

    // Update breadcrumb
    const breadcrumb = this.elt.querySelector('.quiz--breadcrumb span');
    if( breadcrumb && next < this.nbStep ) breadcrumb.innerHTML = `${next + 1}/${this.nbStep}`;
  }

  showResult() {
    const _ = this;
    const step = this.steps[this.current];

    Axios({
      method: 'GET',
      url: `${url}inc/.ajax.php`,
      params: {
        action : 'get-quiz-question-result',
        id_question : step.dataset.question
      }
    })
    .then(response => {
      if( response.data && response.data.result) {
        step.removeAttribute('data-show-result');

        let nbResults = 0;
        // Nb result
        response.data.result.forEach(r => {
          nbResults += parseInt(r.count);
        })
        
        step.classList.add('show-result');

        // Build progress 
        const choices = step.querySelectorAll('.quiz__choices input');
        choices.forEach(choice => {
          let nb = 0;

          // Get nb result
          response.data.result.forEach(r => {
            if( r.id_choice == choice.value ) nb = r.count
          })

          const percent = Math.round(nb / nbResults * 100);

          const progress = document.createElement('div');
          progress.classList.add('progress');
          progress.innerHTML = `<span class="bar" style="width:${percent}%"></span><span class="percent">${percent}%</span>`

          const nextElt = choice.nextElementSibling;

          if(nextElt.classList.contains('w-img')) {
            nextElt.querySelector('.img').append(progress)
          } else {
            nextElt.append(progress);
          }

          setTimeout(() => {
            progress.classList.add('show')
          }, 100)
        })

        // On change l'intitulé du bouton
        const triggers = this.elt.querySelectorAll('[data-next]')
        if( triggers ) {
          triggers.forEach(trigger => trigger.innerHTML = `<span>${_.opts.labels.next}</span>`);
        }

      } else {
        alert('Veuillez réessayer une fois votre navigateur raffraichi.')
      }
    })
    .catch((error) => {
      alert(error);
    })
  }

  destroy() {
  }
}